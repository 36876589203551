import React, { useState } from 'react';
import { getAuth, updateEmail, reauthenticateWithCredential, EmailAuthProvider } from "firebase/auth";
import { useSelector } from 'react-redux';
import { selectUser } from 'features/userSlice';
import { doc, setDoc } from 'firebase/firestore';
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { Input, Card, Row, Col, CardTitle, CardHeader, CardFooter, Button, UncontrolledTooltip, CardSubtitle, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const UpdateUserEmail = () => {
  const auth = getAuth();
  const [newEmail, setNewEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handleEmailUpdate = async (e) => {
    e.preventDefault();
    setMessage('');
    setError('');
  
    const user = auth.currentUser;
  
    if (user && password) {
      const credential = EmailAuthProvider.credential(user.email, password);
  
      try {
        await reauthenticateWithCredential(user, credential);
  
        // Update email in Firebase Authentication first
        await updateEmail(user, newEmail);
  
        // Update only the email field in Firestore
        const docRef = doc(db, "users", user.uid);
        await setDoc(docRef, { email: newEmail }, { merge: true }); // Use merge to update only email
  
        setMessage('Email updated successfully!');
      } catch (error) {
        // Rollback Firebase email update if Firestore fails
        await updateEmail(user, user.email); // Revert email
        setError('Failed to update email. Please try again.');
        console.error(error);
      }
    } else {
      setError('Please enter your password to update your email.');
    }
  };
  
  

  return (
    <>
      <ModalHeader>Update Email</ModalHeader>
      <ModalBody>
        <form onSubmit={handleEmailUpdate}>
            <div>
            <label>New Email:</label>
            <input 
                type="email" 
                value={newEmail} 
                onChange={(e) => setNewEmail(e.target.value)} 
                required 
            />
            </div>
            <div>
            <label>Current Password:</label>
            <input 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                required 
            />
            </div>
            <Button type="submit">Update Email</Button>
        </form>
        {message && <p style={{ color: 'green' }}>{message}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </ModalBody>
    </>
  );
};

export default UpdateUserEmail;
