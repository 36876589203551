import React, { useEffect, useState } from 'react';
import { doc, getDoc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col
} from "reactstrap";

const Changelog = () => {

  const [changelogData, setChangelogData] = useState();
  const [testData, setTestData] = useState();

  useEffect(() => {
    let isMounted = true;
  
    const fetchData = async () => {
      try {
        const docRef = doc(db, "changelog", "LuNLZFQfBnGc1zeE2lNA");
        const docSnap = await getDoc(docRef);
  
        if (docSnap.exists() && isMounted) {
          const changelogData = docSnap.data();

          const sortedKeys = Object.keys(changelogData).sort((a, b) => parseFloat(a) - parseFloat(b));

          const sortedData = sortedKeys.reduce((acc, key) => {
              acc[key] = changelogData[key];
              return acc;
          }, {});

          setChangelogData(sortedData);

        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error('Error fetching document:', error);
      }
    };
  
    fetchData();
  
    // Cleanup function to set isMounted to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, []);

        
  return (
    <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h2">Changelog</CardTitle>
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="lakjsdfadsf">Date</th>
                                <th className="text-center" id="ubqweoivafa">Changes</th>
                              </tr>
                            </thead>
                            {changelogData && Object.entries(changelogData).map(([key, value]) => {
                              // Now, you can render your table rows using the data from each property
                              return (
                                <tbody key={key}>
                                  <tr>
                                    <td className="text-right">{value.date}</td>
                                    <td className="text-left">{value.changes}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
  )
}

export default Changelog