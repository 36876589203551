// /*!

// =========================================================
// * Paper Dashboard PRO React - v1.3.1
// =========================================================

// * Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
// * Copyright 2022 Creative Tim (https://www.creative-tim.com)

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// */
// import { React, useState } from "react";
// import { useSelector } from "react-redux";
// import { selectUser } from "features/userSlice";
// import { setDoc, doc} from "firebase/firestore";
// import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
// import Switch from "react-bootstrap-switch";
// import {
//   Button,
//   Card,
//   CardHeader,
//   CardBody,
//   CardTitle,
//   Label,
//   FormGroup,
//   Input,
//   Table,
//   Row,
//   Col,
//   UncontrolledTooltip,
//   Modal,
//   ModalHeader,
//   ModalBody,
//   ModalFooter,
//   Form,
//   CardSubtitle
// } from "reactstrap";

// function AutoHappyBirthdayTable({props}) {

//     const user = useSelector(selectUser);
//     const [showEditModal, setShowEditModal] = useState(false);

//     const toggleEditModal = () => {
//       setShowEditModal(!showEditModal);
//     }

//   return (
//     <>
//       <div className="content">
//                 <Row>
//                     <Col>
//                       <Card>
//                         <CardHeader>
//                           <CardTitle tag="h4">Auto Happy Birthday</CardTitle>
//                           <CardSubtitle>Launch a Happy Birthday message for your students on their birthday or the nearest class after their birthday.</CardSubtitle>
//                         </CardHeader>
//                         <CardBody>
//                           <Table responsive>
//                             <thead className="text-primary">
//                               <tr>
//                                 <th className="text-center" id="iqo924oib0q9wer" style={{width: '24%'}}>Class</th>
//                                 <th className="text-center" id="r2309a0badvbadf" style={{width: '8%'}}>On/Off</th>
//                                 <th className="text-center" id="y930292035823508" style={{width: '16%'}}>Launch Time</th>
//                                 <th className="text-center" id="mjasdf4380asdfb" style={{width: '16%'}}>Background</th>
//                                 <th className="text-center" id="nzxcviasdf823" style={{width: '16%'}}>Sound</th>
//                                 <th className="text-center" id="nzxcviasdf823" style={{width: '8%'}}>Close</th>
//                                 <th className="text-center" style={{width: '8%'}}>Edit</th>
//                               </tr>
//                               {/* <UncontrolledTooltip delay={0} target={"iqo924oib0q9wer"}>This is the time at which your timer will start.</UncontrolledTooltip>
//                               <UncontrolledTooltip delay={0} target={"r2309a0badvbadf"}>Your timer will start with this many seconds and countdown from there.</UncontrolledTooltip>
//                               <UncontrolledTooltip delay={0} target={"y930292035823508"}>Your timer will be active on these days only.</UncontrolledTooltip>
//                               <UncontrolledTooltip delay={0} target={"mjasdf4380asdfb"}>This is the message that will display at the top of your timer. Ex. "You should be in your seat by:"</UncontrolledTooltip>
//                               <UncontrolledTooltip delay={0} target={"nzxcviasdf823"}>If checked, a message of your choice will show upon countdown completion.</UncontrolledTooltip> */}
//                             </thead>
//                             {user && user.classes.map((classMap, index) => {
//                             return(
//                             <tbody key={index}>
//                               <tr>
//                                 <td className="text-center" >{classMap.className}</td>
//                                 <td className="text-center" >{classMap.autoHappyBirthday?.active ? "On" : "Off" }</td>
//                                 <td className="text-center" >{classMap.autoHappyBirthday?.launchTime || "--"}</td>
//                                 <td className="text-center">{classMap.autoHappyBirthday?.background || "--"}</td>
//                                 <td className="text-center">{classMap.autoHappyBirthday?.sound || "--"}</td>
//                                 <td className="text-center">{classMap.autoHappyBirthday?.close || "--"}</td>
//                                 <td className="text-center">
//                                   <Button
//                                     className="btn-icon btn-neutral"
//                                     color="info"
//                                     id="tooltip159182735"
//                                     size="md"
//                                     type="button"
//                                     onClick={() => toggleEditModal()}
//                                   >
//                                     <i className="fa fa-edit" />
//                                   </Button>
//                                   <UncontrolledTooltip
//                                     delay={0}
//                                     target="tooltip159182735"
//                                   >
//                                     Edit
//                                   </UncontrolledTooltip>
//                                 </td>
//                               </tr>
//                               </tbody>
//                             )})}
//                           </Table>
//                         </CardBody>
//                       </Card>
//                     </Col>         
//                 </Row>
//       </div>

//       <Modal isOpen={showEditModal} toggle={toggleEditModal}>
//         <ModalHeader>

//         </ModalHeader>
//         <ModalBody>
//           <Row>
//             <Col>
//               <Label>On/Off</Label>
//               <Switch
//                 id="active"
//               />
//             </Col>
//             <Col>
//               <Label>Launch Time</Label>
//               <Input
//                 type="select"
//                 id="launchTime"
//               >
//                 <option value="0">At class start</option>
//                 <option value="5">5 minutes after class start</option>
//                 <option value="10">10 minutes after class start</option>
//                 <option value="15">15 minutes after class start</option>
//                 <option value="20">20 minutes after class start</option>
//                 <option value="25">25 minutes after class start</option>
//                 <option value="30">30 minutes after class start</option>
//                 <option value="random">Random</option>
//               </Input>
//             </Col>
//             <Col>
//               <Label>Background</Label>
//             </Col>
//             <Col>
//               <Label>Close</Label>
//               <Input
//                 type="select"
//                 id="close"
//               >
//                 <option value="manual">Manual</option>
//                 <option value="20 seconds">Show for 20 Seconds</option>
//                 <option value="30 seconds">Show for 30 Seconds</option>
//                 <option value="40 seconds">Show for 40 Seconds</option>
//                 <option value="50 seconds">Show for 50 Seconds</option>
//                 <option value="60 seconds">Show for 60 Seconds</option>
//               </Input>
//             </Col>
//             <Col>
//             </Col>
//           </Row>
//         </ModalBody>
//         <ModalFooter>

//         </ModalFooter>
//       </Modal>
//     </>
//   );
// }

// export default AutoHappyBirthdayTable;



import { React, useState } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc} from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import Switch from "react-bootstrap-switch";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  Badge,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  CardSubtitle
} from "reactstrap";

function AutoHappyBirthdayTable({props}) {
    const user = useSelector(selectUser);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedClass, setSelectedClass] = useState(null);
    const [classIdToEdit, setClassIdToEdit] = useState(null);
    const [formState, setFormState] = useState({
        active: false,
        launchTime: "",
        background: "",
        sound: "",
        close: ""
    });

    const toggleEditModal = (classData = null, classId) => {
        if (classData) {
            setClassIdToEdit(classId);
            setFormState({
                active: classData.autoHappyBirthday?.active || false,
                launchTime: classData.autoHappyBirthday?.launchTime || 0,
                background: classData.autoHappyBirthday?.background || "",
                sound: classData.autoHappyBirthday?.sound || "",
                close: classData.autoHappyBirthday?.close || 30
            });
            setSelectedClass(classData);
        }
        setShowEditModal(!showEditModal);
    }

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormState({
            ...formState,
            [id]: value
        });
    }

    const handleSwitchChange = (id, value) => {
        setFormState({
            ...formState,
            [id]: value
        });
    }

    // const handleSubmit = async () => {
    //     if (selectedClass) {
    //         const updatedClassData = {
    //             ...selectedClass,
    //             autoHappyBirthday: {
    //                 ...formState
    //             }
    //         };

    //         try {
    //             // Update the Firestore document
    //             await setDoc(doc(db, "users", user.uid), {
    //                 classes: user.classes.map((cls) =>
    //                     cls.classId === classIdToEdit ? updatedClassData : cls
    //                 )
    //             });

    //             // Optionally, close the modal after saving
    //             toggleEditModal();
    //             setClassIdToEdit(null);
    //         } catch (error) {
    //             console.error("Error updating document: ", error);
    //         }
    //         setClassIdToEdit(null);
    //     }
    // }


    const handleSubmit = async () => {
      if (selectedClass) {
          try {
              const docRef = doc(db, "users", user.uid);
  
              // Create a deep copy of the user object
              const userCopy = JSON.parse(JSON.stringify(user));
  
              // Find the class index based on classId
              const classIndex = userCopy.classes.findIndex(
                  (c) => c.classId === classIdToEdit
              );
  
              if (classIndex !== -1) {
                  // Update the autoHappyBirthday field with the new form data
                  userCopy.classes[classIndex].autoHappyBirthday = {
                      ...formState
                  };

                  console.log("userCopy => ", userCopy)
  
                  // Update the document in Firestore with the modified userCopy
                  await setDoc(docRef, userCopy);
  
                  // Optionally, close the modal after saving
                  toggleEditModal();
                  setClassIdToEdit(null);
              }
          } catch (error) {
              console.error("Error updating document: ", error);
          }
      }
  };  

  return (
    <>
      <div className="content">
        <Row>
          <Col>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Auto Happy Birthday <Badge color="danger">Beta</Badge></CardTitle>
                <CardSubtitle>
                  Launch a Happy Birthday message for your students on their birthday or the nearest class after their birthday. 
                  You must have entered student's birthdays in the roster for a class for Auto Happy Birthday to work. 
                  To enter student birthdays click the Roster button in the Classes table above. 
                </CardSubtitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th className="text-center" style={{width: '22%'}}>Class</th>
                      <th className="text-center" id="j34820asioa" style={{width: '9%'}}>Roster Data</th>
                      <th className="text-center" id="basdf098bqw" style={{width: '9%'}}>On/Off</th>
                      <th className="text-center" id="as9d8ifiq0b" style={{width: '14%'}}>Launch Time</th>
                      <th className="text-center" id="i94i8nb9eeq" style={{width: '14%'}}>Background</th>
                      <th className="text-center" id="s2234insidv" style={{width: '14%'}}>Sound</th>
                      <th className="text-center" id="kslwir90909" style={{width: '9%'}}>Close</th>
                      <th className="text-center" style={{width: '9%'}}>Edit</th>
                      <UncontrolledTooltip delay={0} target={"j34820asioa"}>Have you entered birthdays in your roster for this class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"basdf098bqw"}>Do you want to use Auto-Happy Birthday for this class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"as9d8ifiq0b"}>When should the Happy Birthday popup launch during your class?</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"i94i8nb9eeq"}>Coming Soon...</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"s2234insidv"}>Coming Soon...</UncontrolledTooltip>
                      <UncontrolledTooltip delay={0} target={"kslwir90909"}>How long do you want the Happy Birthday popup to appear on the screen before automatically closing?</UncontrolledTooltip>
                    </tr>
                  </thead>
                  {user && user.classes?.length > 0 ? user.classes.map((classMap, index) => {
                    return(
                      <tbody key={index}>
                        <tr>
                          <td className="text-center" >{classMap.className}</td>
                          <td className="text-center">{classMap.rosterData?.roster?.some(student => student.birthday) ? <Badge color="success">Yes</Badge> : <Badge color="danger">No</Badge>}</td>                          
                          <td className="text-center" >{classMap.autoHappyBirthday?.active ? <Badge color="success">On</Badge> : <Badge color="danger">Off</Badge> }</td>
                          <td className="text-center" >
                            {classMap.autoHappyBirthday?.launchTime === 0
                              ? "At start"
                              : classMap.autoHappyBirthday?.launchTime
                              ? `After ${classMap.autoHappyBirthday?.launchTime} seconds`
                              : "--"
                            }
                          </td>
                          <td className="text-center">{classMap.autoHappyBirthday?.background || "--"}</td>
                          <td className="text-center">{classMap.autoHappyBirthday?.sound || "--"}</td>
                          <td className="text-center">
                            {classMap.autoHappyBirthday?.close === "manual"
                              ? "Manual"
                              : classMap.autoHappyBirthday?.close
                              ? `After ${classMap.autoHappyBirthday?.close} seconds`
                              : "--"
                            }
                          </td>                          
                          <td className="text-center">
                            <Button
                              className="btn-icon btn-neutral"
                              color="info"
                              size="md"
                              type="button"
                              onClick={() => toggleEditModal(classMap, classMap.classId)}
                            >
                              <i className="fa fa-edit" />
                            </Button>
                          </td>
                        </tr>
                      </tbody>
                    )}) : <p>You must have at least one class to use this feature.</p>}
                </Table>
              </CardBody>
            </Card>
          </Col>         
        </Row>
      </div>

      <Modal isOpen={showEditModal} toggle={toggleEditModal}>
        <ModalHeader toggle={toggleEditModal}>
          Edit Auto Happy Birthday for {user.classes.find(cls => cls.classId === classIdToEdit)?.className}
        </ModalHeader>
        <ModalBody>
        {(() => {
          const selectedClass = user.classes.find(cls => cls.classId === classIdToEdit);
          
          // Check if there are no birthdays in the roster
          if (selectedClass && !selectedClass.rosterData?.roster?.some(student => student.birthday)) {
            return (
              <div className="alert alert-warning" role="alert">
                Notice: This class does not contain any birthdays in its roster. Without birthdays entered, Auto Happy Birthday will not work. You can edit a class's roster by clicking the Roster button in the classes table.
              </div>
            );
          }
          return null;
        })()}
          <Row>
            <Col md="2">
              <Label for="active" id="basdf098bqw">On/Off</Label>
              <div>
                <Switch
                  id="active"
                  onChange={(el, state) => handleSwitchChange('active', state)}
                  value={formState.active}
                  onText="On"
                  offText="Off"
                />
              </div>
            </Col>
            <Col md="5">
              <Label for="launchTime" id="as9d8ifiq0b">Launch Time</Label>
              <Input
                type="select"
                id="launchTime"
                value={formState.launchTime}
                onChange={handleInputChange}
              >
                <option value="0">At class start</option>
                <option value="5">5 minutes after class start</option>
                <option value="10">10 minutes after class start</option>
                <option value="15">15 minutes after class start</option>
                <option value="20">20 minutes after class start</option>
                <option value="25">25 minutes after class start</option>
                <option value="30">30 minutes after class start</option>
                <option value="random">Random</option>
              </Input>
            </Col>
            <Col md="5">
              <Label for="close" id="kslwir90909">Close</Label>
              <Input
                type="select"
                id="close"
                value={formState.close}
                onChange={handleInputChange}
              >
                <option value="manual">Manual</option>
                <option value="20">Show for 20 Seconds</option>
                <option value="30">Show for 30 Seconds</option>
                <option value="40">Show for 40 Seconds</option>
                <option value="50">Show for 50 Seconds</option>
                <option value="60">Show for 60 Seconds</option>
              </Input>
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col>
              <Label disabled for="background" id="i94i8nb9eeq">Background Choice (Coming Soon)</Label>
              <Input
                id="background"
                value={formState.background}
                onChange={handleInputChange}
                disabled
              />
            </Col>
            <Col>
              <Label disabled for="sound" id="s2234insidv">Sound (Coming Soon)</Label>
              <Input
                id="sound"
                value={formState.sound}
                onChange={handleInputChange}
                disabled
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSubmit}>Save</Button>
          <Button color="secondary" onClick={() => (toggleEditModal() && setClassIdToEdit(null))}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default AutoHappyBirthdayTable;
