import React from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import ClassesTable from "./tables/ClassesTable";
import AutoCountdownTable from "components/AutoCountdownTable";
import AutoReminderTable from "components/AutoReminderTable";
import AutoHappyBirthdayTable from "components/AutoHappyBirthdayTable";


function AddEditView() {

  const user = useSelector(selectUser); 

  return (
    <>
      <div className="content">
        <ClassesTable props={user.classes} />
        <AutoCountdownTable props={user.autoCountdowns} />
        <AutoReminderTable props={user.reminders} />
        <AutoHappyBirthdayTable />
      </div>
    </>
  );
}

export default AddEditView;
