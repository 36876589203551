
// import CheckoutButton from 'components/CheckoutForm';
// import React, { useEffect, useState, useRef } from 'react';
// import Toggle from 'react-toggle';
// import { useSelector } from 'react-redux';
// import { selectUser } from 'features/userSlice';
// import { Input, Card, Row, Col, CardTitle, CardHeader, CardFooter, CardBody, Button, UncontrolledTooltip, CardSubtitle, Tooltip } from 'reactstrap';
// import { doc, setDoc } from 'firebase/firestore';
// import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
// import Contact from './Contact';
// import { NavLink } from 'react-router-dom';
// import Changelog from 'components/Changelog';
// import ReactBSAlert from "react-bootstrap-sweetalert";
// import Switch from "react-bootstrap-switch";
// import { createShareCode } from 'RandomId';

// const Thing = () => {
//   const user = useSelector(selectUser);
//   const [settings, setSettings] = useState(user && user.settings || {});
//   const [dirty, setDirty] = useState(false); // Track if settings are "dirty"
//   const [saved, setSaved] = useState(false)
//   const toggleRef = useRef(null);
//   const [alert, setAlert] = useState(null)
//   const [tooltipOpen, setTooltipOpen] = useState(false);
//   const toggle = () => setTooltipOpen(!tooltipOpen);

//   // Track the initial settings to compare with later
//   const initialSettingsRef = useRef(settings);

//   console.log(settings)

//   useEffect(() => {
//     const updateSettings = async () => {
//       if (!dirty || !user) return; // Only update if settings are dirty and user exists

//       const docRef = doc(db, 'users', user.uid);
//       const userCopy = { ...user, settings };

//       try {
//         await setDoc(docRef, userCopy); // Update Firestore
//         console.log("Here")
//         setSaved(true); // Indicate settings have been saved
//         setDirty(false); // Reset dirty flag since settings are now in sync
//       } catch (error) {
//         console.error('Error updating settings in Firestore:', error);
//       }
//     };

//     updateSettings();

//     const timer = setTimeout(() => {
//       setSaved(false);
//     }, 1200);

//     return () => clearTimeout(timer);
//   }, [dirty, settings, user]);

//   const handleSettingChange = (newSettings) => {
//     // Check if the new settings are different from the initial settings
//     if (JSON.stringify(initialSettingsRef.current) !== JSON.stringify(newSettings)) {
//       setDirty(true); // Mark settings as dirty if they've changed
//     } else {
//       setDirty(false); // Reset dirty flag if reverted to original
//     }
//     setSettings(newSettings); // Update local settings state
//   };


//   const areYouSureAlertForAlteredScheduleMode = () => {
//     setAlert(
//       <ReactBSAlert
//         warning
//         style={{ display: "block", marginTop: "-100px" }}
//         title="Are you sure?"
//         onConfirm={() => {
//           setSettings((prev) => ({
//           ...prev,
//           alteredSchedule: !prev.alteredSchedule,
//           }))
//           hideAlert()
//         }}
//         onCancel={() => hideAlert()}
//         confirmBtnBsStyle="info"
//         cancelBtnBsStyle="danger"
//         confirmBtnText="Yes!"
//         cancelBtnText="Cancel"
//         showCancel
//         btnSize=""
//       >
//         This will turn off all of your automations.
//       </ReactBSAlert>
//     );
//   };

//   const hideAlert = () => {
//     setAlert(null);
//   };

//   const handleShareCodeToggle = async () => {
//     if (user.settings.useShareCode === undefined || user.shareCode === undefined) {
//       //Create share code
//       const shareCode = createShareCode();

//       //store it in shareCodes collection
//       try {
//         console.log("attempting to write share code to database")
//         await setDoc(doc(db, "shareCodes", shareCode), {uid: user.uid})
//       } catch {
//         console.log("Failed to write share code to database")
//         return; // Exit the function if the share code creation fails
//       }
//       //add it to user object
//       try {
//         console.log("attempting to update user document")
//         const docRef = doc(db, 'users', user.uid);
  
//         const userCopy = JSON.parse(JSON.stringify(user));
//         userCopy.settings = { ...userCopy.settings, useShareCode: true };

//         userCopy.shareCode = shareCode

//         // Update the document in Firestore
//         await setDoc(docRef, userCopy);
//       } catch {
//         console.log("Failed to write share code to user document")
//         return;
//       }
//       setSettings((prev) => ({
//         ...prev,
//         useShareCode: true,
//       }));
//     } else {
//       setSettings((prev) => ({
//         ...prev,
//         useShareCode: !prev.useShareCode,
//       }));
//     }
//   }

//   const calculateDaysRemaining = () => {
//     if (user) {
//       const currentDate = new Date();
//       const milliseconds = user.accountCreated?.seconds ? user.accountCreated.seconds * 1000 + (user.accountCreated?.nanoseconds || 0) / 1e6 : undefined;
//       const accountCreatedDate = new Date(milliseconds);
//       const daysRemaining = 30 - (Math.floor((currentDate - accountCreatedDate) / (1000 * 60 * 60 * 24)));
//       return daysRemaining;
//     }
//   }

//   // ChatGPT says you don't need this even though it created it for me to fix blurring issue making the data save endlessly
//   // const blurToggle = () => {
//   //   if (toggleRef.current) {
//   //     toggleRef.current.blur();
//   //   }
//   // };

//   return (
//     <div className='content'>
//       {alert}
//     <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
//       <Col md='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
//         <Card style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//           <CardHeader>
//             <CardTitle>
//               <h2>Settings</h2>
//             </CardTitle>
//           </CardHeader>

//       <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
//         <Col>
//           <CardSubtitle>
//             Present
//           </CardSubtitle>
//           <hr></hr>
//           <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
//             <Switch
//               value={settings.showSeconds}
//               // defaultValue={true}
//               // ref={toggleRef}
//               onChange={() => {
//                 handleSettingChange((prev) => ({
//                   ...prev,
//                   showSeconds: !prev.showSeconds,
//                 }));
//               }}
//             />
//             <span style={{marginLeft: '10px'}}>Display seconds on Clock</span>
//           </label>
//           <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}} id={"alteredSchedule"}>
//             <UncontrolledTooltip
//               delay={0}
//               target={"alteredSchedule"}
//               placement="top"
//             >
//               A convenient way to turn off any automations when your school is operating on a different schedule for the day.
//             </UncontrolledTooltip>
//             <Switch
//               onColor='default'
//               offColor='default'
//               checked={settings.alteredSchedule}
//               defaultValue={settings.alteredSchedule}
//               // ref={toggleRef}
//               onChange={() => !settings.alteredSchedule ? areYouSureAlertForAlteredScheduleMode() : setSettings((prev) => ({
//                 ...prev,
//                 alteredSchedule: !prev.alteredSchedule,
//               }))}
//             />
//             <span style={{marginLeft: '10px'}}>Altered Schedule Mode</span>
//           </label>
//           <label style={{margin: '15px'}} id="marqueeSpeed">
//             <UncontrolledTooltip
//               delay={0}
//               target={"marqueeSpeed"}
//               placement="top"
//             >
//               Change the speed of the scrolling marquee on the present screen.
//             </UncontrolledTooltip>
//           <span>Marquee Speed</span>
//           <Input
//             id="exampleSelect"
//             name="select"
//             type="select"
//             value={settings.marqueeSpeed || '150'}
//             onChange={(e) => {
//               setSettings((prev) => ({
//                 ...prev,
//                 marqueeSpeed: e.target.value
//               }));
//             }}
//           > <option value={'0'}>
//               None
//             </option>
//             <option value={'50'}>
//               Largo
//             </option>
//             <option value={'100'}>
//               Andante
//             </option>
//             <option value={'150'}>
//               Moderato
//             </option>
//             <option value={'200'}>
//               Allegro
//             </option>
//             <option value={'250'}>
//               Vivace
//             </option>
//           </Input>
//           </label>
//           <label style={{margin: '15px'}} id="screenTimeout">
//             <UncontrolledTooltip
//               delay={0}
//               target={"screenTimeout"}
//               placement="top"
//             >
//               Use this setting to keep the present screen turned on.
//             </UncontrolledTooltip>
//           <span>Screen Timeout</span>
//           <Input
//             id="exampleSelect"
//             name="select"
//             type="select"
//             value={settings.screenTimeout || '60'}
//             onChange={(e) => {
//               setSettings((prev) => ({
//                 ...prev,
//                 screenTimeout: e.target.value
//               }));
//             }}
//           > <option value={'0'}>
//               Use System Setting
//             </option>
//             <option value={'30'}>
//               30 minutes
//             </option>
//             <option value={'45'}>
//               45 minutes
//             </option>
//             <option value={'60'}>
//               1 hour (default)
//             </option>
//             <option value={'90'}>
//               1 hour 30 minutes
//             </option>
//             <option value={'120'}>
//               2 hours
//             </option>
//             <option value={'180'}>
//               3 hours
//             </option>
//             <option value={'240'}>
//               4 hours
//             </option>
//           </Input>
//           </label>
//         </Col>
//         <Col>
//           <CardSubtitle>
//             Display
//           </CardSubtitle>
//           <hr></hr>
//           <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
//             <Switch
//               checked={settings.useActiveDates === true}
//               defaultValue={settings.useActiveDates === true}
//               // ref={toggleRef}
//               onChange={() => {
//                 setSettings((prev) => ({
//                   ...prev,
//                   useActiveDates: !prev.useActiveDates,
//                 }));
//               }}
//             />
//             <span style={{marginLeft: '10px'}}>Use Active Dates</span>
//           </label>
//           {/* <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
//             <Switch
//               checked={settings.hideBarsWhenFullscreen === true}
//               defaultValue={settings.hideBarsWhenFullscreen === true}
//               // ref={toggleRef}
//               onChange={() => {
//                 setSettings((prev) => ({
//                   ...prev,
//                   hideBarsWhenFullscreen: !prev.hideBarsWhenFullscreen,
//                 }));
//               }}
//             />
//             <span style={{marginLeft: '10px'}}>Fullscreen Hide Nav and Launch Bar</span>
//           </label> */}
//           <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
//             <Toggle
//               ref={toggleRef}
//               disabled
//             />
//             <span style={{marginLeft: '10px'}}>Dark Mode (coming soon)</span>
//           </label>
//           <label style={{margin: '15px'}}>
//           <span>Theme Selector (coming soon)</span>
//           <Input
//             ref={toggleRef}
//             disabled
//           >
//           </Input>
//           </label>
//         </Col>
//         <Col>
//           <CardSubtitle>
//             Scheduling
//           </CardSubtitle>
//           <hr></hr>
//           <label style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}} id={"multiWeek"}>
//             <UncontrolledTooltip
//               delay={0}
//               target={"multiWeek"}
//               placement="top"
//             >
//               Select this option if your class schedule changes week-to-week.
//             </UncontrolledTooltip>
//             <Toggle
//               checked={settings.multiWeek}
//               ref={toggleRef}
//               onChange={() => {
//                 setSettings((prev) => ({
//                   ...prev,
//                   multiWeek: !prev.multiWeek,
//                 }));
//               }}
//             />
//             <span style={{marginLeft: '10px'}}>Multi-Week Schedule</span>
//           </label>
//           {settings.multiWeek && 
//           <>
//             <label style={{margin: '15px'}}>
//             <span>How many different weeks does your schedule contain?</span>
//             <Input
//               id="numberOfWeeks"
//               name="select"
//               type="select"
//               defaultValue={settings.numberOfWeeks}
//               onChange={(e) => {
//                 setSettings((prev) => ({
//                   ...prev,
//                   numberOfWeeks: e.target.value
//                 }));
//               }}
//             >
//               <option disabled value={1}>
//                 --
//               </option>
//               <option value={2}>
//                 2 Weeks
//               </option>
//               <option value={3}>
//                 3 Weeks
//               </option>
//               <option value={4}>
//                 4 Weeks
//               </option>
//               <option value={5}>
//                 5 Weeks
//               </option>
//               <option value={6}>
//                 6 Weeks
//               </option>
//               <option value={7}>
//                 7 Weeks
//               </option>
//             </Input>
//             </label>
//             <label style={{margin: '15px'}}>
//             <span>Current Week</span>
//             <Input
//               id="currentWeek"
//               name="select"
//               type="select"
//               value={settings.currentWeek}
//               onChange={(e) => {
//                 setSettings((prev) => ({
//                   ...prev,
//                   currentWeek: e.target.value
//                 }));
//               }}
//             >
//               <option value={"A"}>
//                 Week A
//               </option>
//               <option value={"B"}>
//                 Week B
//               </option>
//               {settings.numberOfWeeks >= "3" && (
//                 <option value={"C"}>
//                   Week C
//                 </option>
//               )}
//               {settings.numberOfWeeks >= "4" && (
//                 <option value={"D"}>
//                   Week D
//                 </option>
//               )}
//               {settings.numberOfWeeks >= "5" && (
//                 <option value={"E"}>
//                   Week E
//                 </option>
//               )}
//               {settings.numberOfWeeks >= "6" && (
//                 <option value={"F"}>
//                   Week F
//                 </option>
//               )}
//               {settings.numberOfWeeks >= "7" && (
//                 <option value={"G"}>
//                   Week G
//                 </option>
//               )}
//             </Input>
//             </label>
//           </>
//           }
//           <label style={{margin: '15px'}}>
//             <span>Do you need different times for the same class?</span>
//             <UncontrolledTooltip
//               delay={0}
//               target={"differentTimes"}
//               placement="top"
//               autohide={false}
//             >
//               If you have any class that regularly meets at different times this is the option for you. It will give you the ability to set specific times for each day the class meets.
//             </UncontrolledTooltip>
//             <Input
//               id="differentTimes"
//               name="select"
//               type="select"
//               value={settings.differentTimes}
//               onChange={(e) => {
//                 const isDifferentTimes = e.target.value === "true"; // Convert the value to a boolean
//                 setSettings((prev) => ({
//                   ...prev,
//                   differentTimes: isDifferentTimes
//                 }));
//               }}
//             >
//               <option value="false">
//                 No
//               </option>
//               <option value="true">
//                 Yes
//               </option>
//             </Input>
//           </label>
//         </Col>
//         <Col>
//           <CardSubtitle>
//             Share
//           </CardSubtitle>
//           <hr></hr>
//             <label id="switch-useShareCode" style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
//               <Switch
//                 checked={settings.useShareCode === true}
//                 defaultValue={settings.useShareCode === true}
//                 // ref={toggleRef}
//                 onChange={() => handleShareCodeToggle()}
//               />
//               <span style={{marginLeft: '10px'}}>Share Lesson Content</span>
//             </label>
//             <Tooltip
//               placement="top"
//               autohide={false}
//               isOpen={tooltipOpen}
//               target="switch-useShareCode"
//               toggle={toggle}
//             >
//               Share your lesson content with anyone by using a lesson launchpad share link.
//             </Tooltip>
//             {user.settings?.useShareCode && (
//               <>
//                 <div>
//                   Your share URL is:
//                 </div>
//                 <br></br>
//                 <div style={{ textAlign: 'center', fontSize: '14px' }}>
//                   {user && 
//                     <a href={`https://www.lessonlaunchpad.com/share/${user.shareCode}`} id="shareURL">
//                       {`www.lessonlaunchpad.com/share/${user.shareCode}`}
//                     </a>
//                   }
//                   <UncontrolledTooltip
//                     delay={0}
//                     target={"shareURL"}
//                     placement="top"
//                     autohide={false}
//                   >
//                     Share this link with others so they can view a read-only copy of your present screen content.
//                   </UncontrolledTooltip>
//                 </div>
//               </>
//             )}
//             <br></br>
//             <CardSubtitle>
//             Account
//           </CardSubtitle>
//             <hr></hr>
//             {user && user.premium ?
//               <Row style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
//                 <a href='https://billing.stripe.com/p/login/9AQ29QgsR09R9bOfYY'>
//                     <Button>
//                         Customer Portal
//                     </Button>
//                 </a>
//               </Row>
//             :  user.trial ?
//               <Row style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
//                 <br></br>
//                 <p>You are currently on a 30-day free trial.</p>
//                 <p>You have {calculateDaysRemaining()} days remaining on your free trial.</p>
//                 <NavLink to={{pathname: '/pricing'}} >
//                     <Button style={{height: '60px'}}>
//                         Subscribe to Lesson Launchpad
//                     </Button>
//                 </NavLink>
//               </Row>
//             :
//               <Row style={{margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center'}}>
//                 <br></br>
//                 <p>Your trial has ended.</p>
//                 <NavLink to={{pathname: '/pricing'}} >
//                     <Button style={{height: '60px'}}>
//                         Subscribe to Lesson Launchpad
//                     </Button>
//                 </NavLink>
//               </Row>
//             }
//         </Col>
//       </Col>
      
      
//       <CardFooter style={{height: '20px'}}>
//       {saved && saved ? <div style={{display: 'flex', justifyContent: 'left', alignItems: 'center'}}><i className="fa fa-check" style={{marginRight: '5px', marginBottom: '15px', color: 'green'}}/><p style={{color: 'green'}}>Settings Saved</p></div> : <></>}
//       </CardFooter>
//     </Card>
//     </Col>
//     </Row>
//     <br></br>
//     <Row style={{display: 'flex', justifyContent: 'center'}}>
//       <Contact />
//     </Row>
//     <Row style={{display: 'flex', justifyContent: 'center'}}>
//       <h3><a href="https://docs.google.com/forms/d/e/1FAIpQLSdN4JmHvASf4h_-8btowJfug5jbvv5rxOVH4x0j6uERVTYMmg/viewform?usp=sf_link" target="_blank">Click HERE to leave a Testimonial for our Landing Page.</a></h3>
//     </Row>
//     {/* <br></br>
//     <Row style={{display: 'flex', justifyContent: 'center'}}>
//       <h2>FAQ (coming soon)</h2>
//     </Row> */}
//     <br></br>
//     <Row style={{display: 'flex', justifyContent: 'center'}}>
//       <Changelog />
//     </Row>
//     </div>
//   );
// };

// export default Thing;

import CheckoutButton from 'components/CheckoutForm';
import React, { useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { selectUser } from 'features/userSlice';
import { Input, Card, Row, Col, CardTitle, CardHeader, CardFooter, Button, UncontrolledTooltip, CardSubtitle, Tooltip, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import Contact from './Contact';
import { NavLink, useHistory, Prompt } from 'react-router-dom';
import Changelog from 'components/Changelog';
import Switch from "react-bootstrap-switch";
import { createShareCode } from 'RandomId';
import ReactBSAlert from "react-bootstrap-sweetalert";
import UpdateUserEmail from 'components/UpdateUserEmail';

const Thing = () => {
  const user = useSelector(selectUser);
  const [settings, setSettings] = useState(user?.settings || {});
  const [dirty, setDirty] = useState(false); // Track if settings are "dirty"
  const [saved, setSaved] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [modal, setModal] = useState(false);
  const history = useHistory();
  const [alert, setAlert] = useState(null);
  const [updateUserEmailModal, setUpdateUserEmailModal] = useState(false);

  const toggleUpdateUserEmailModal = () => setUpdateUserEmailModal(!updateUserEmailModal);
 
  // Track the initial settings to compare with later
  const initialSettingsRef = useRef(settings);

  const toggleModal = () => setModal(!modal);

  const handleSettingChange = (newSettings) => {
    console.log(newSettings)
    if (JSON.stringify(initialSettingsRef.current) !== JSON.stringify(newSettings)) {
      setDirty(true); // Mark settings as dirty if they've changed
    } else {
      setDirty(false); // Reset dirty flag if reverted to original
    }
    setSettings(newSettings); // Update local settings state
  };

  const saveSettings = async () => {
    if (!user || !dirty) return; // Only save if settings are dirty and user exists

    try {
      const docRef = doc(db, "users", user.uid);

      // Create a deep copy of the user object
      const userCopy = JSON.parse(JSON.stringify(user));

      // Merge new settings into existing settings
      userCopy.settings = {
        ...userCopy.settings,
        ...settings
      };

      console.log("userCopy => ", userCopy)

      // Update the document in Firestore with the modified userCopy
      await setDoc(docRef, userCopy);

      setSaved(true); // Indicate settings have been saved
      setDirty(false); // Reset dirty flag since settings are now in sync
      initialSettingsRef.current = settings; // Update initial settings to the current saved state
  
      // Set a timer to reset the saved state after 2 seconds
      setTimeout(() => {
        setSaved(false);
      }, 2000);
  
    } catch (error) {
      console.error('Error updating settings in Firestore:', error);
    }
  };

  const handleNavigateAway = (e) => {
    if (typeof e === "object" && e.preventDefault) {
      e.preventDefault(); // Prevent navigation if preventDefault is available
    }
  
    if (dirty) {
      toggleModal(); // Show the modal if there are unsaved changes
      return false; // Return false to prevent navigation
    }
  
    return true; // Allow navigation if there are no unsaved changes
  };

  const handleContinueWithoutSaving = () => {
    toggleModal();
    setDirty(false); // Reset dirty flag to avoid loop
    history.push(history.location.pathname); // Continue navigation
  };

  const handleShareCodeToggle = async () => {
    console.log("user.settings.useShareCode => ", user.settings.useShareCode)
    console.log("user.shareCode => ", user.shareCode)
    if (user.settings.useShareCode === undefined || user.shareCode === undefined) {
      // Create share code
      const shareCode = createShareCode();

      // Store it in shareCodes collection
      try {
        console.log("Attempting to write share code to database");
        await setDoc(doc(db, "shareCodes", shareCode), { uid: user.uid });
      } catch {
        console.log("Failed to write share code to database");
        return; // Exit the function if the share code creation fails
      }

      // Add it to user object
      try {
        console.log("Attempting to update user document");
        const docRef = doc(db, 'users', user.uid);

        const userCopy = JSON.parse(JSON.stringify(user));

        userCopy.settings = {
          ...userCopy.settings,
          useShareCode: true
        };

        userCopy.shareCode = shareCode

        // Update the document in Firestore
        await setDoc(docRef, userCopy);
      } catch {
        console.log("Failed to write share code to user document");
        return;
      }

      handleSettingChange({
        ...settings,
        useShareCode: true,
      })
    } else {

      if (user.settings.useShareCode === true) { // Everything already exists and the user is just trying to turn off useShareCode
        handleSettingChange({
          ...settings,
          useShareCode: !user.settings?.useShareCode,
        })
      } else { // The user is trying to turn on useShareCode
        if (user.shareCode) { // I think this should always be true because of the user.shareCode === undefined check above

          const docRef = doc(db, 'shareCodes', user.shareCode);
          console.log("docRef => ", docRef)
  
          try {
            //Search shareCodes collection for user's shareCode
            const docSnap = await getDoc(docRef);
            
            if (docSnap.exists()) { //If it already exists
              console.log('Share Code is in collection');
              handleSettingChange({
                ...settings,
                useShareCode: true,
              })
            } else {
              // Attempting to write missing share code to shareCodes collection
              try {
                console.log("Attempting to write share code to shareCodes collection");
                await setDoc(doc(db, "shareCodes", user.shareCode), { uid: user.uid });
                handleSettingChange({
                  ...settings,
                  useShareCode: true,
                })
              } catch {
                console.log("Failed to write share code to shareCodes collection");
                return; // Exit the function if the share code creation fails
              }
            }
          } catch (error) {
            console.error('Error checking document existence:', error);
          }
  
        }
      }
    }
  };

  const areYouSureAlertForAlteredScheduleMode = (boolean) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => {
          handleSettingChange({
            ...settings,
            alteredSchedule: boolean,
          })
          hideAlert()
        }}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        This will turn off all of your automations.
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const calculateDaysRemaining = () => {
    if (user) {
      const currentDate = new Date();
      const milliseconds = user.accountCreated?.seconds ? user.accountCreated.seconds * 1000 + (user.accountCreated?.nanoseconds || 0) / 1e6 : undefined;
      const accountCreatedDate = new Date(milliseconds);
      const daysRemaining = 30 - (Math.floor((currentDate - accountCreatedDate) / (1000 * 60 * 60 * 24)));
      return daysRemaining;
    }
  }

  return (
    <div className='content'>
      {alert}
      <Prompt
        when={dirty}
        message={handleNavigateAway}
      />
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Did you mean to save?</ModalHeader>
        <ModalBody>
          You have unsaved changes. Would you like to save before leaving?
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { saveSettings(); toggleModal(); }}>
            Save
          </Button>
          <Button color="secondary" onClick={handleContinueWithoutSaving}>
            Continue without saving
          </Button>
        </ModalFooter>
      </Modal>
      
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <Col md='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Card style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <CardHeader>
              <CardTitle>
                <h2>Settings</h2>
              </CardTitle>
            </CardHeader>

            <Col style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              <Col>
                <CardSubtitle>
                  Present
                </CardSubtitle>
                <hr></hr>
                <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                {console.log(settings)}
                  <Switch
                    value={settings.showSeconds}
                    onChange={(el, boolean) => handleSettingChange({
                      ...settings,
                      showSeconds: boolean,
                    })}
                  />
                  <span style={{ marginLeft: '10px' }}>Display seconds on Clock</span>
                </label>
                <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }} id={"alteredSchedule"}>
                  <UncontrolledTooltip
                    delay={0}
                    target={"alteredSchedule"}
                    placement="top"
                  >
                    A convenient way to turn off any automations when your school is operating on a different schedule for the day.
                  </UncontrolledTooltip>
                  <Switch
                    value={settings.alteredSchedule || false}
                    onChange={(el, boolean) => !settings.alteredSchedule ? areYouSureAlertForAlteredScheduleMode(boolean) : handleSettingChange({
                      ...settings,
                      alteredSchedule: boolean,
                    })}
                  />
                  <span style={{ marginLeft: '10px' }}>Altered Schedule Mode</span>
                </label>
                <label style={{ margin: '15px' }} id="marqueeSpeed">
                  <UncontrolledTooltip
                    delay={0}
                    target={"marqueeSpeed"}
                    placement="top"
                  >
                    Change the speed of the scrolling marquee on the present screen.
                  </UncontrolledTooltip>
                  <span>Marquee Speed</span>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    value={settings.marqueeSpeed || '150'}
                    onChange={(e) => handleSettingChange({
                      ...settings,
                      marqueeSpeed: e.target.value,
                    })}
                  > <option value={'0'}>
                      None
                    </option>
                    <option value={'50'}>
                      Largo
                    </option>
                    <option value={'100'}>
                      Andante
                    </option>
                    <option value={'150'}>
                      Moderato
                    </option>
                    <option value={'200'}>
                      Allegro
                    </option>
                    <option value={'250'}>
                      Vivace
                    </option>
                  </Input>
                </label>
                <label style={{ margin: '15px' }} id="screenTimeout">
                  <UncontrolledTooltip
                    delay={0}
                    target={"screenTimeout"}
                    placement="top"
                  >
                    Use this setting to keep the present screen turned on.
                  </UncontrolledTooltip>
                  <span>Screen Timeout</span>
                  <Input
                    id="exampleSelect"
                    name="select"
                    type="select"
                    value={settings.screenTimeout || '60'}
                    onChange={(e) => handleSettingChange({
                      ...settings,
                      screenTimeout: e.target.value,
                    })}
                  > <option value={'0'}>
                      Use System Setting
                    </option>
                    <option value={'30'}>
                      30 minutes
                    </option>
                    <option value={'45'}>
                      45 minutes
                    </option>
                    <option value={'60'}>
                      1 hour (default)
                    </option>
                    <option value={'90'}>
                      1 hour 30 minutes
                    </option>
                    <option value={'120'}>
                      2 hours
                    </option>
                    <option value={'180'}>
                      3 hours
                    </option>
                    <option value={'240'}>
                      4 hours
                    </option>
                  </Input>
                </label>
              </Col>
              <Col>
                <CardSubtitle>
                  Display
                </CardSubtitle>
                <hr></hr>
                <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <Switch
                    value={settings.useActiveDates || false}
                    onChange={(el, boolean) => handleSettingChange({
                      ...settings,
                      useActiveDates: boolean,
                    })}
                  />
                  <span style={{ marginLeft: '10px' }}>Use Active Dates</span>
                </label>
                {/* <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                  <Switch
                    disabled
                  />
                  <span style={{ marginLeft: '10px' }}>Dark Mode (coming soon)</span>
                </label> */}
                {/* <label style={{ margin: '15px' }}>
                  <span>Theme Selector (coming soon)</span>
                  <Input
                    disabled
                  >
                  </Input>
                </label> */}
                <br></br>
                <CardSubtitle>
                  Share
                </CardSubtitle>
                <hr></hr>
                  <label id="switch-useShareCode" style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <Switch
                      value={settings.useShareCode || false}
                      onChange={handleShareCodeToggle}
                    />
                    <span style={{ marginLeft: '10px' }}>Share Lesson Content</span>
                  </label>
                  <Tooltip
                    placement="top"
                    autohide={false}
                    isOpen={tooltipOpen}
                    target="switch-useShareCode"
                    toggle={toggle}
                  >
                    Share your lesson content with anyone by using a lesson launchpad share link.
                  </Tooltip>
                  {settings?.useShareCode && (
                    <>
                      <div>
                        Your share URL is:
                      </div>
                      <br></br>
                      <div style={{ textAlign: 'center', fontSize: '14px' }}>
                        {user && 
                          <a href={`https://www.lessonlaunchpad.com/share/${user.shareCode}`} id="shareURL">
                            {`www.lessonlaunchpad.com/share/${user.shareCode}`}
                          </a>
                        }
                        <UncontrolledTooltip
                          delay={0}
                          target={"shareURL"}
                          placement="top"
                          autohide={false}
                        >
                          Share this link with others so they can view a read-only copy of your present screen content.
                        </UncontrolledTooltip>
                      </div>
                    </>
                  )}
                  <br></br>
              </Col>
              <Col>
                <CardSubtitle>
                  Scheduling
                </CardSubtitle>
                <hr></hr>
                <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }} id={"multiWeek"}>
                  <UncontrolledTooltip
                    delay={0}
                    target={"multiWeek"}
                    placement="top"
                  >
                    Select this option if your class schedule changes week-to-week. Ex: A Week / B Week Schedule
                  </UncontrolledTooltip>
                  <Switch
                    value={settings.multiWeek || false}
                    onChange={(el, boolean) => handleSettingChange({
                      ...settings,
                      multiWeek: boolean,
                    })}
                  />
                  <span style={{ marginLeft: '10px' }}>Multi-Week Schedule</span>
                </label>
                {/* <label style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }} id={"multiDay"}>
                  <UncontrolledTooltip
                    delay={0}
                    target={"multiDay"}
                    placement="top"
                  >
                    Select this option if your class schedule changes everyday on a rotating basis. Ex: 4 Day Cycle or 7 Day Cycle
                  </UncontrolledTooltip>
                  <Switch
                    value={settings.multiDay || false}
                    // onChange={(el, boolean) => handleSettingChange({
                    //   ...settings,
                    //   multiWeek: boolean,
                    // })}
                  />
                  <span style={{ marginLeft: '10px' }}>Multi-Day Cycle Schedule</span>
                </label> */}
                {settings.multiWeek && 
                <>
                  <label style={{ margin: '15px' }}>
                  <span>How many different weeks does your schedule contain?</span>
                  <Input
                    id="numberOfWeeks"
                    name="select"
                    type="select"
                    value={settings.numberOfWeeks || 1}
                    onChange={(e) => handleSettingChange({
                      ...settings,
                      numberOfWeeks: e.target.value,
                    })}
                  >
                    <option disabled value={1}>
                      --
                    </option>
                    <option value={2}>
                      2 Weeks
                    </option>
                    <option value={3}>
                      3 Weeks
                    </option>
                    <option value={4}>
                      4 Weeks
                    </option>
                    <option value={5}>
                      5 Weeks
                    </option>
                    <option value={6}>
                      6 Weeks
                    </option>
                    <option value={7}>
                      7 Weeks
                    </option>
                  </Input>
                  </label>
                  <label style={{ margin: '15px' }}>
                  <span>Current Week</span>
                  <Input
                    id="currentWeek"
                    name="select"
                    type="select"
                    value={settings.currentWeek || ""}
                    onChange={(e) => handleSettingChange({
                      ...settings,
                      currentWeek: e.target.value,
                    })}
                  >
                    <option disabled value={""}>
                      --
                    </option>
                    <option value={"A"}>
                      {settings.multiWeek ? "Week A" : "Day A"}
                    </option>
                    <option value={"B"}>
                      {settings.multiWeek ? "Week B" : "Day B"}
                    </option>
                    {settings.numberOfWeeks >= "3" && (
                      <option value={"C"}>
                        {settings.multiWeek ? "Week C" : "Day C"}
                      </option>
                    )}
                    {settings.numberOfWeeks >= "4" && (
                      <option value={"D"}>
                        {settings.multiWeek ? "Week D" : "Day D"}
                      </option>
                    )}
                    {settings.numberOfWeeks >= "5" && (
                      <option value={"E"}>
                        {settings.multiWeek ? "Week E" : "Day E"}
                      </option>
                    )}
                    {settings.numberOfWeeks >= "6" && (
                      <option value={"F"}>
                        {settings.multiWeek ? "Week F" : "Day F"}
                      </option>
                    )}
                    {settings.numberOfWeeks >= "7" && (
                      <option value={"G"}>
                        {settings.multiWeek ? "Week G" : "Day G"}
                      </option>
                    )}
                  </Input>
                  </label>
                </>
                }
                <label style={{ margin: '15px' }}>
                  <span>Do you need different times for the same class?</span>
                  <UncontrolledTooltip
                    delay={0}
                    target={"differentTimes"}
                    placement="top"
                    autohide={false}
                  >
                    If you have any class that regularly meets at different times this is the option for you. It will give you the ability to set specific times for each day the class meets.
                  </UncontrolledTooltip>
                  <Input
                    id="differentTimes"
                    name="select"
                    type="select"
                    value={settings.differentTimes || null}
                    onChange={(e) => {
                      const isDifferentTimes = e.target.value === "true"; // Convert the value to a boolean
                      handleSettingChange({
                      ...settings,
                      differentTimes: isDifferentTimes,
                    })}}
                    // onChange={(e) => {
                    //   const isDifferentTimes = e.target.value === "true"; // Convert the value to a boolean
                    //   setSettings((prev) => ({
                    //     ...prev,
                    //     differentTimes: isDifferentTimes
                    //   }));
                    // }}
                  >
                    <option value={null}>
                      --
                    </option>
                    <option value="false">
                      No
                    </option>
                    <option value="true">
                      Yes
                    </option>
                  </Input>
                </label>
              </Col>
              <Col>
                
                  <CardSubtitle>
                  Account
                </CardSubtitle>
                  <hr></hr>
                  {user && user.premium ?
                    <Row style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                      <a href='https://billing.stripe.com/p/login/9AQ29QgsR09R9bOfYY' style={{width: '100%'}}>
                          <Button style={{ fontSize: '12px', width: '100%' }}>
                              Customer Portal
                          </Button>
                      </a>
                    </Row>
                  :  user.trial ?
                    <Row style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                      <p>You are currently on a 30-day free trial.</p>
                      <p>You have {calculateDaysRemaining()} days remaining on your free trial.</p>
                      <NavLink to={{pathname: '/pricing'}} style={{width: '100%'}}>
                          <Button style={{ fontSize: '12px', width: '100%' }}>
                              Subscribe to Lesson Launchpad
                          </Button>
                      </NavLink>
                    </Row>
                  :
                    <Row style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                      <br></br>
                      <p>Your trial has ended.</p>
                      <NavLink to={{pathname: '/pricing'}} style={{width: '100%'}}>
                          <Button style={{ fontSize: '12px', width: '100%' }}>
                              Subscribe to Lesson Launchpad
                          </Button>
                      </NavLink>
                    </Row>
                  }
                  <Row style={{ margin: '15px', display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
                    <Button style={{ fontSize: '12px', width: '100%' }} onClick={toggleUpdateUserEmailModal}>
                      Change User Email
                    </Button>
                  </Row>
              </Col>
            </Col>
            <CardFooter>
              <Button color="primary" onClick={saveSettings} style={{ marginTop: '10px', display: 'flex', alignItems: 'center' }}>
                {saved ? "Settings Saved" : "Save Settings"}
                {saved && <i className="fa fa-check" style={{ marginLeft: '10px', color: 'white' }} />}
              </Button>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Modal isOpen={updateUserEmailModal} toggle={toggleUpdateUserEmailModal} style={{width: '300px'}}>
        <UpdateUserEmail />
      </Modal>
      <br></br>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Contact />
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <h3><a href="https://docs.google.com/forms/d/e/1FAIpQLSdN4JmHvASf4h_-8btowJfug5jbvv5rxOVH4x0j6uERVTYMmg/viewform?usp=sf_link" target="_blank">Click HERE to leave a Testimonial for our Landing Page.</a></h3>
      </Row>
      <br></br>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        <Changelog />
      </Row>
    </div>
  );
};

export default Thing;
