/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {React, useState} from "react";
import {NavLink, Route} from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc} from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ContentBoxesTable from "/Users/michaeltucker/Documents/coding-projects/client/src/views/tables/ContentBoxesTable.js";
import RosterTable from "components/RosterTable";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  CardSubtitle,
  Badge
} from "reactstrap";

function ClassesTable({props}) {

    const [alert, setAlert] = useState(null);
    const user = useSelector(selectUser);
    const [multiWeek, setMultiWeek] = useState(user.settings?.multiWeek)
    const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings?.numberOfWeeks || 2)
    const [differentTimes, setDifferentTimes] = useState(user.settings?.differentTimes)
    const [showDatePickerContentBoxStorage, setShowDatePickerContentBoxStorage] = useState(false)
    const dayMapping = {
      "Monday": "M",
      "Tuesday": "T",
      "Wednesday": "W",
      "Thursday": "Th",
      "Friday": "F",
      "Saturday": "S",
      "Sunday": "Su",
    };
    const dayOrder = ["M", "T", "W", "Th", "F", "S", "Su"];
    const weeks = ["A", "B", "C", "D", "E", "F", "G"];
    const weeksOrder = weeks.slice(0, numberOfWeeks)
    const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
    const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
    const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const suffix = ['', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'   ]
    const [date, setDate] = useState( x => {
      let dateObject = new Date()
      let dateData = {
        dayName: `${daysLong[dateObject.getDay()]}`,
        dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
        year: dateObject.getFullYear(),
        month: dateObject.getMonth(),
        day: dateObject.getDate(),
        databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
      }
      return dateData;
    });

    const handleDateForward = () => {
      let dateObject = new Date(date.year, date.month, date.day)
      let nextDay = new Date(dateObject.getTime() + (24 * 60 * 60 * 1000)); // Adds one day
      let dateData = {
        dayName: `${daysLong[nextDay.getDay()]}`,
        dateString: `${monthsLong[nextDay.getMonth()]} ${nextDay.getDate()}${suffix[nextDay.getDate()]}, ${nextDay.getFullYear()}`,
        year: nextDay.getFullYear(),
        month: nextDay.getMonth(),
        day: nextDay.getDate(),
        databaseFormattedDate: nextDay.toLocaleDateString('en-CA')
      }
      setDate(dateData)
    }
  
    const handleDateBackward = () => {
      let dateObject = new Date(date.year, date.month, date.day)
      let previousDay = new Date(dateObject.getTime() - (24 * 60 * 60 * 1000)); // Adds one day
      let dateData = {
        dayName: `${daysLong[previousDay.getDay()]}`,
        dateString: `${monthsLong[previousDay.getMonth()]} ${previousDay.getDate()}${suffix[previousDay.getDate()]}, ${previousDay.getFullYear()}`,
        year: previousDay.getFullYear(),
        month: previousDay.getMonth(),
        day: previousDay.getDate(),
        databaseFormattedDate: previousDay.toLocaleDateString('en-CA')
      }
      setDate(dateData)
    }
  
    const handleDateSet = (e) => {
      let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
      let dateData = {
        dayName: `${daysLong[dateObject.getDay()]}`,
        dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
        year: dateObject.getFullYear(),
        month: dateObject.getMonth(),
        day: dateObject.getDate(),
        databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
      }
      setDate(dateData)
      setShowDatePicker(!showDatePicker);
    }
  
    const handleDateSetContentBoxStorage = (e) => {
      let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
      let dateData = {
        dayName: `${daysLong[dateObject.getDay()]}`,
        dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
        year: dateObject.getFullYear(),
        month: dateObject.getMonth(),
        day: dateObject.getDate(),
        databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
      }
      setDate(dateData)
      setShowDatePickerContentBoxStorage(!showDatePickerContentBoxStorage);
    }

    const tooManyClassesAlert = () => {
      setAlert(
        <ReactBSAlert
          style={{ display: "block", marginTop: "-100px" }}
          title="Class Limit Reached!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Upgrade to Pro for more classes!
        </ReactBSAlert>
      );
    };

    const warningWithConfirmAndCancelMessage = (classId) => {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => deleteClass(classId)}
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this class or the content it contains!
        </ReactBSAlert>
      );
    };
  
    const successDelete = () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your class has been deleted.
        </ReactBSAlert>
      );
    };
  
    const cancelDelete = () => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your class is safe :)
        </ReactBSAlert>
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    const deleteClass = async (classId) => {
      console.log(classId)
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
  
        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === classId
        );
    
        // Remove the class at the specified index
        userCopy.classes.splice(classIndex, 1);
        console.log(userCopy)
    
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
  
        successDelete()
    
        console.log('Content box deleted successfully!');
      } catch (error) {
        console.error('Error deleting content box:', error);
      }
    }

    const handleMoveUp = (index) => {
      if (index > 0) {
        // Swap the current item with the one above it
        const updatedClasses = [...props];
        [updatedClasses[index], updatedClasses[index - 1]] = [
          updatedClasses[index - 1],
          updatedClasses[index],
        ];
        updateClasses(updatedClasses)
      }
    };
  
    const handleMoveDown = (index) => {
      if (index < props.length - 1) {
        // Swap the current item with the one below it
        const updatedClasses = [...props];
        [updatedClasses[index], updatedClasses[index + 1]] = [
          updatedClasses[index + 1],
          updatedClasses[index],
        ];
        updateClasses(updatedClasses)
      }
    };

    const updateClasses = async (updatedClasses) => {
      try {
        const docRef = doc(db, "users", user.uid);

        const userCopy = JSON.parse(JSON.stringify(user));
    
        userCopy.classes = updatedClasses
        // Update the document in Firestore
        await setDoc(docRef, userCopy);

        console.log("saved")
      } catch (error) {
        alert('Error updating document:', error);
      }
    }

    function convertTo12HourFormat(time24) {
      // Split the time into hours and minutes
      const [hours, minutes] = time24.split(':').map(Number);
    
      // Determine whether it's AM or PM
      const period = hours >= 12 ? 'PM' : 'AM';
    
      // Convert hours to 12-hour format
      const hours12 = hours % 12 || 12;
    
      // Format the time as "hh:mm AM/PM"
      const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
    
      return time12;
    }

    function listActiveStartTimes(data) {
      const uniqueTimes = new Set();
    
      for (const key in data) {
        const days = data[key];
    
        for (const dayKey in days) {
          const day = days[dayKey];
    
          if (day.active) {
            uniqueTimes.add(convertTo12HourFormat(day.startTime));
          }
        }
      }
    
      return Array.from(uniqueTimes).join(', ');
    }

    function listActiveEndTimes(data) {
      const uniqueTimes = new Set();
    
      for (const key in data) {
        const days = data[key];
    
        for (const dayKey in days) {
          const day = days[dayKey];
    
          if (day.active) {
            uniqueTimes.add(convertTo12HourFormat(day.endTime));
          }
        }
      }
    
      return Array.from(uniqueTimes).join(', ');
    }

    const [storageModal, setStorageModal] = useState(false);
    const [rosterModal, setRosterModal] = useState(false);
    const [classId, setClassId] = useState();

  const toggleStorageModal = (classId) => {
    hideAlert()
    setClassId(classId)
    setStorageModal(!storageModal)
  }

  const toggleRosterModal = (classId) => {
    hideAlert()
    setClassId(classId)
    setRosterModal(!rosterModal)
  }

  return (
    <>
      <div className="content">


        <Modal contentClassName="content-box-storage-table" isOpen={storageModal} toggle={toggleStorageModal}>
          <ModalHeader toggle={toggleStorageModal} style={{padding: '10px'}}>
            <Row>
              <Col xs="4"/>
              <Col xs="4">
            {user.settings.useActiveDates ? (
              <div className="contentBoxTableDatePicker">
                {/* <UncontrolledTooltip
                  delay={0}
                  target="present-navbar-datepicker-section"
                  placement="top"
                >
                    Functionality Coming Soon
                </UncontrolledTooltip> */}
                <Button
                  className="btn-icon btn-neutral"
                  color="secondary"
                  size="md"
                  type="button"
                  onClick={() => handleDateBackward()}
                >
                  <i className="nc-icon nc-minimal-left" />
                </Button>
                  { showDatePickerContentBoxStorage ? (
                    <ReactDatetime
                      // timeFormat={"h:mm:a"}
                      // value={localAgendaBoxData?.startTime}
                      open
                      initialValue={date}
                      className="present-date-picker"
                      timeFormat={false}
                      closeOnSelect
                      closeOnClickOutside
                      onClose={() => setShowDatePickerContentBoxStorage(!showDatePickerContentBoxStorage)}
                      onChange={(e) => handleDateSetContentBoxStorage(e)}
                      inputProps={{
                        placeholder: "Time Picker Here"
                      }}
                    />
                  ):(
                    <div style={{marginTop: '0px', cursor: 'pointer'}} onClick={() => setShowDatePickerContentBoxStorage(!showDatePickerContentBoxStorage)}>
                      <div style={{textAlign: 'center', fontSize: '20px'}}>{date.dayName}</div>
                      <div style={{textAlign: 'center', fontSize: '15px'}}>{date.dateString}</div>
                    </div>
                  )}
                <Button
                  className="btn-icon btn-neutral"
                  color="secondary"
                  size="md"
                  type="button"
                  onClick={() => handleDateForward()}
                >
                  <i className="nc-icon nc-minimal-right" />
                </Button>
              </div>
            )
            : null }
              </Col>
              <Col xs="4"/>
            </Row>
          </ModalHeader>
          <ModalBody >
            <ContentBoxesTable props={{current: classId}} date={date}/>
          </ModalBody>
        </Modal>


        <Modal contentClassName="content-box-storage-table" isOpen={rosterModal} toggle={toggleRosterModal} scrollable>
          <ModalHeader toggle={toggleRosterModal}/>
          <ModalBody >
            <RosterTable classId={classId}/>
          </ModalBody>
        </Modal>


                <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">Classes</CardTitle>
                          <CardSubtitle>The Classes Table holds the basic information for each of your classes. By entering a Start Time, Lesson Launchpad will automatically navigate to your class at the time you've selected.
                            By unchecking the Active box, a class will no longer appear in the Present Classes dropdown. You can also uncheck the Marquee and Clock boxes to hide those if you do not wish to use them.
                          </CardSubtitle>
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="Period" style={{width: '4%'}}>Period</th>
                                <th className="text-center" id="Name" style={{width: '16%'}}>Name</th>
                                <th className="text-center" id="Days" style={{width: '15%'}}>Class Days</th>
                                <th className="text-center" id="Elephant" style={{width: '9%'}}>Start Time</th>
                                {/* <th className="text-center" id="Zebra" style={{width: '9%'}}>End Time</th> */}
                                <th className="text-center" id="Roster" style={{width: '9%'}}>Roster</th>
                                <th className="text-center" id="Announcements" style={{width: '7%'}}>Announcements</th>
                                <th className="text-center" id="Boxes" style={{width: '5%'}}>Boxes</th>
                                <th className="text-center" id="Marquee" style={{width: '5%'}}>Marquee</th>
                                <th className="text-center" id="Clock" style={{width: '5%'}}>Clock</th>
                                <th className="text-center" id="Giraffe" style={{width: '5%'}}>Active</th>
                                <th className="text-center" id="Order" style={{width: '8%'}}>Order</th>
                                <th className="text-center" style={{width: '8%'}}>Actions</th>
                              </tr>
                              <UncontrolledTooltip delay={0} target={"Elephant"}>Entering a start time will allow Lesson Launchpad to attempt to automatically switch classes for you at the time you enter.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"Period"}>The period or other identifier you've chosen for your class.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"Days"}>The days of the week that this class meets.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"Name"}>The name you've chosen for your class.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"Marquee"}>If checked, Marquee will be visible.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"Clock"}>If checked, Clock will be visible.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"Giraffe"}>If checked, Class will be available in the Present Classes dropdown.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"Boxes"}>Click the icon to view a list of your content boxes for this class.</UncontrolledTooltip>
                            </thead>
                            {props && props.map((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-center" style={{ textTransform: 'uppercase' }}>{item.period}</td>
                                <td className="text-center" style={{ textTransform: 'capitalize' }}>{item.className}</td>
                                <td className={user.settings.multiWeek ? "text-left" : "text-center"}>
                                {!multiWeek && item.schedule?.A ? (
                                  <div>
                                    {dayOrder
                                      .filter(day => item.schedule.A[Object.keys(dayMapping).find(key => dayMapping[key] === day)].active)
                                      .join(', ') || "--"}
                                  </div>
                                ) : null}
                                {multiWeek && weeksOrder.map((week) => (
                                  <div key={week}>
                                    <strong>Week {week}:</strong>{" "}
                                    {dayOrder
                                      .filter((day) =>
                                        item.schedule && item.schedule[week] && item.schedule[week][Object.keys(dayMapping).find((key) => dayMapping[key] === day)].active
                                      )
                                      .join(', ')}
                                  </div>
                                ))}
                                </td>
                                <td className="text-center">{listActiveStartTimes(item.schedule)}</td>
                                {/* <td className="text-center">{listActiveEndTimes(item.schedule)}</td> */}
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip818330590"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleRosterModal(item.classId)}
                                  >
                                    <i className="fa fa-users" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip818330590"
                                  >
                                    View/Edit Class Roster
                                  </UncontrolledTooltip>
                                </td>
                                <td className="text-center">
                                  <NavLink 
                                    to={{
                                      pathname: '/announcement-form',
                                      state: { classId: item.classId, announcements: null, path: props.location }
                                    }}
                                    style={{ textDecoration: 'none', cursor: 'text' }}
                                  >
                                    <Button
                                      className="btn-icon btn-neutral"
                                      color="info"
                                      id="tooltip801483390"
                                      size="md"
                                      type="button"
                                    >
                                      <i className="fa fa-bullhorn" />
                                    </Button>
                                  </NavLink>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip801483390"
                                  >
                                    View Announcements
                                  </UncontrolledTooltip>
                                </td>
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip808333390"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleStorageModal(item.classId)}
                                  >
                                    <i className="nc-icon nc-app" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808333390"
                                  >
                                    View Content Boxes
                                  </UncontrolledTooltip>
                                </td>
                                <td className="text-center">{item.showMarquee ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                                <td className="text-center">{item.showClock ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                                <td className="text-center">{item.active ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159182282"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveUp(index)}
                                  >
                                  <i className="nc-icon nc-minimal-up" />
                                  </Button>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159185983"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveDown(index)}
                                  >
                                  <i className="nc-icon nc-minimal-down" />
                                  </Button>
                                </td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="success"
                                    id="tooltip159182735"
                                    size="md"
                                    type="button"
                                  >
                                    <NavLink to={{pathname: "/form", state: {item}}}><i className="fa fa-edit" /></NavLink>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item.classId)}
                                  >
                                    <i className="fa fa-trash" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                            <button
                              style={{
                                background: '#0bc0df',
                                border: '1px solid #0bc0df',
                                color: 'white',
                                margin: '2px',
                                borderRadius: '4px',
                                outline: 'none',
                                outlineColor: '#0bc0df',
                                font: 'Montserrat',
                                fontSize: '14px',
                                textTransform: 'uppercase',
                                fontWeight: '600',
                                padding: '10px 20px',
                                width: '150px',
                                height: '40px',
                                boxShadow: '0 6px 10px -4px rgba(0, 0, 0, 0.15)',
                                height: '40px', 
                              }}
                            >
                              <NavLink to={{pathname: "/form"}} style={{color: 'white'}}>Add Class</NavLink>
                            </button>
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
      </div>
    </>
  );
}

export default ClassesTable;