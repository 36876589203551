/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { React, useState } from "react";
import { NavLink } from "react-router-dom";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc} from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  CardSubtitle
} from "reactstrap";

function AutoCountdownTable({props}) {

    const [alert, setAlert] = useState(null);
    const user = useSelector(selectUser);
    const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek)
    const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2)
    const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
    const dayMapping = {
      "Monday": "M",
      "Tuesday": "T",
      "Wednesday": "W",
      "Thursday": "Th",
      "Friday": "F",
      "Saturday": "S",
      "Sunday": "Su",
    };
    const dayOrder = ["M", "T", "W", "Th", "F", "S", "Su"];
    const weeks = ["A", "B", "C", "D", "E", "F", "G"];
    const weeksOrder = weeks.slice(0, numberOfWeeks)

    const warningWithConfirmAndCancelMessage = (index) => {
      setAlert(
        <ReactBSAlert
          warning
          style={{ display: "block", marginTop: "-100px" }}
          title="Are you sure?"
          onConfirm={() => deleteCountdownTimer(index)}
          onCancel={() => cancelDelete()}
          confirmBtnBsStyle="info"
          cancelBtnBsStyle="danger"
          confirmBtnText="Yes, delete it!"
          cancelBtnText="Cancel"
          showCancel
          btnSize=""
        >
          You will not be able to recover this timer!
        </ReactBSAlert>
      );
    };
  
    const successDelete = () => {
      setAlert(
        <ReactBSAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title="Deleted!"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your timer has been deleted.
        </ReactBSAlert>
      );
    };
  
    const cancelDelete = () => {
      setAlert(
        <ReactBSAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Cancelled"
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnBsStyle="info"
          btnSize=""
        >
          Your timer is safe :)
        </ReactBSAlert>
      );
    };

    const hideAlert = () => {
      setAlert(null);
    };

    const deleteCountdownTimer = async (index) => {
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
    
        // Remove the class at the specified index
        userCopy.countdownTimers.splice(index, 1);
    
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
  
        successDelete()
    
        console.log('Countdown Timer deleted successfully!');
      } catch (error) {
        console.error('Error deleting content box:', error);
      }
    }

    const duplicateCountdownTimer = async (index) => {
      try {
        const docRef = doc(db, "users", user.uid);
    
        const userCopy = JSON.parse(JSON.stringify(user));
    
        // Make a copy of the selected countdown
        const countdownCopy = {...userCopy.countdownTimers[index]};

        // Add copy to the countdownTimers array
        userCopy.countdownTimers.push(countdownCopy);

        // Update the document in Firestore
        await setDoc(docRef, userCopy);
      
      } catch (error) {
        console.error('Error deleting content box:', error);
      }
    }

    function convertTo12HourFormat(time24) {
      // Split the time into hours and minutes
      const [hours, minutes] = time24.split(':').map(Number);
    
      // Determine whether it's AM or PM
      const period = hours >= 12 ? 'PM' : 'AM';
    
      // Convert hours to 12-hour format
      const hours12 = hours % 12 || 12;
    
      // Format the time as "hh:mm AM/PM"
      const time12 = `${hours12}:${String(minutes).padStart(2, '0')} ${period}`;
    
      return time12;
    }

    function listActiveStartTimes(data) {
      const uniqueStartTimes = new Set();
    
      for (const key in data) {
        const days = data[key];
    
        for (const dayKey in days) {
          const day = days[dayKey];
    
          if (day.active) {
            uniqueStartTimes.add(convertTo12HourFormat(day.startTime));
          }
        }
      }
    
      return Array.from(uniqueStartTimes).join(', ');
    }

  return (
    <>
      <div className="content">
                <Row>
                    <Col>
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">Countdown Timers</CardTitle>
                          <CardSubtitle>Countdown Timers can be used to trigger timers at specific times. The most common use is the "You must be in your seat by X minutes after the bell" alarm. Lesson Launchpad will automatically launch your timer at the time you request.</CardSubtitle>
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-center" id="lakjsdfadsf" style={{width: '8%'}}>Start Time</th>
                                <th className="text-center" id="ubqweoivafa" style={{width: '8%'}}>Duration</th>
                                <th className="text-center" id="opoanealkjd" style={{width: '15%'}}>Days Active</th>
                                <th className="text-center" id="yqweribasdf" style={{width: '15%'}}>Header Message</th>
                                <th className="text-center" id="qlkabadsfkd" style={{width: '15%'}}>Alert Message</th>
                                <th className="text-center" id="pldjabakela" style={{width: '12%'}}>Alert Sound</th>
                                <th className="text-center" id="pasdfaskedf" style={{width: '7%'}}>Background</th>
                                <th className="text-center" id="basdfitjdba" style={{width: '7%'}}>Active</th>
                                <th className="text-center" style={{width: '15%'}}>Actions</th>
                              </tr>
                              <UncontrolledTooltip delay={0} target={"lakjsdfadsf"}>This is the time at which your timer will start.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"ubqweoivafa"}>Your timer will start with this many seconds and countdown from there.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"opoanealkjd"}>Your timer will be active on these days only.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"yqweribasdf"}>This is the message that will display at the top of your timer. Ex. "You should be in your seat by:"</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"qlkabadsfkd"}>If checked, a message of your choice will show upon countdown completion.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"pldjabakela"}>If checked, a sound of your choice will play upon countdown completion.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"pasdfaskedf"}>If checked, the background behind the timer will be darker.</UncontrolledTooltip>
                              <UncontrolledTooltip delay={0} target={"basdfitjdba"}>If checked, this countdown timer will trigger at the requested time.</UncontrolledTooltip>
                            </thead>
                            {user && user.countdownTimers && user.countdownTimers.map((countdown, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-center" >{!differentTimes ? convertTo12HourFormat(countdown.startTime) : listActiveStartTimes(countdown.schedule)}</td>
                                <td className="text-center" >{countdown.duration} seconds</td>
                                <td className={user.settings.multiWeek ? "text-left" : "text-center"}>
                                {!multiWeek && countdown.schedule?.A ? (
                                  <div>
                                    {dayOrder
                                      .filter(day => countdown.schedule.A[Object.keys(dayMapping).find(key => dayMapping[key] === day)].active)
                                      .join(', ')}
                                  </div>
                                ) : null}
                                {multiWeek && weeksOrder.map((week) => (
                                  <div key={week}>
                                    <strong>Week {week}:</strong>{" "}
                                    {dayOrder
                                      .filter((day) =>
                                        countdown.schedule && countdown.schedule[week] && countdown.schedule[week][Object.keys(dayMapping).find((key) => dayMapping[key] === day)].active
                                      )
                                      .join(', ')}
                                  </div>
                                ))}
                                </td>
                                <td className="text-center">{`${countdown.header}` || "--"}</td>
                                <td className="text-center">{`${countdown.alertMessage}` || "--"}</td>
                                <td className="text-center">{countdown.youtube ? countdown.youtubeSrc : countdown.alertSoundName}</td>
                                <td className="text-center">{countdown.backdrop ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                                <td className="text-center">{countdown.active ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip159182735"
                                    size="md"
                                    type="button"
                                  >
                                    <NavLink to={{pathname: "/countdown-form", state: {countdown, index}}}><i className="fa fa-edit" /></NavLink>
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip80495849302"
                                    size="md"
                                    type="button"
                                    onClick={() => duplicateCountdownTimer(index)}
                                  >
                                    <i className="fa fa-copy" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip80495849302"
                                  >
                                    Duplicate
                                  </UncontrolledTooltip>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(index)}
                                  >
                                    <i className="fa fa-trash" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                            <button
                              style={{
                                background: '#0bc0df',
                                border: '1px solid #0bc0df',
                                color: 'white',
                                margin: '2px',
                                borderRadius: '4px',
                                outline: 'none',
                                outlineColor: '#0bc0df',
                                font: 'Montserrat',
                                fontSize: '14px',
                                textTransform: 'uppercase',
                                fontWeight: '600',
                                padding: '10px 20px',
                                width: '200px',
                                height: '40px',
                                boxShadow: '0 6px 10px -4px rgba(0, 0, 0, 0.15)',
                                height: '40px', 
                              }}
                            >
                              <NavLink to={{pathname: "/countdown-form"}} style={{color: 'white'}}>Add Countdown</NavLink>
                            </button>
                        </CardBody>
                      </Card>
                    </Col>         
                </Row>
      </div>
    </>
  );
}

export default AutoCountdownTable;