import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { Modal, ModalBody, Button, Card } from "reactstrap";
import { useLocation } from "react-router-dom";

const UseAutoHappyBirthday = ({ classId, classData }) => {
  const logs = false;
  const user = useSelector(selectUser);
  const location = useLocation();
  const [showBirthdayModal, setShowBirthdayModal] = useState(false);
  const [birthdays, setBirthdays] = useState([]);

  const formatTimeUntilLaunched = (milliseconds) => {
    const hours = Math.floor(milliseconds / 3600000);
    const minutes = Math.floor((milliseconds % 3600000) / 60000);
    const seconds = Math.floor((milliseconds % 60000) / 1000);
  
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const formatCurrentAndStartTime = (currentTime) => {
    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert hours to 12-hour format
    return `${formattedHours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;
  };

  const parseTimeString = (timeString) => {
    const [hours, minutes] = timeString.split(':').map(Number);
    const date = new Date();
  
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0); // Set seconds to 0
  
    return date.getTime();
  };

  useEffect(() => {
    const classIndex = user?.classes.findIndex((x) => x.classId === classId);
    const roster = user?.classes[classIndex]?.rosterData?.roster || [];
    
    if (logs) console.log('Class Index:', classIndex);
    if (logs) console.log('Roster:', roster);

    if (roster.length > 0) {
      const schedule = user?.classes[classIndex]?.schedule || {};
      const currentDay = new Date();
      const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const currentDayName = dayNames[currentDay.getDay()];
      const currentWeek = user.settings.multiWeek ? user.settings.currentWeek : "A";
      const numberOfWeeks = user.settings.multiWeek ? user.settings.numberOfWeeks : 1;

      if (logs) console.log('Class is Active today:', schedule[currentWeek][currentDayName].active);
      if (schedule[currentWeek][currentDayName].active) {
        if (logs) console.log('Current Day:', currentDayName);
        if (logs) console.log('Current Week:', currentWeek);
        if (logs) console.log('Number of Weeks:', numberOfWeeks);

        const lastClassDayName = findLastActiveDay(schedule, currentWeek, numberOfWeeks, currentDayName);
        const lastClassDayIndex = dayNames.indexOf(lastClassDayName);
        const currentDayIndex = dayNames.indexOf(currentDayName);
        const daysAgo = (currentDayIndex - lastClassDayIndex + 7) % 7;

        if (logs) console.log('Last Active Day:', lastClassDayName);
        if (logs) console.log('Days Ago:', daysAgo);

        const lastClassDayDate = new Date();
        lastClassDayDate.setDate(currentDay.getDate() - daysAgo);

        if (logs) console.log('Last Class Day Date:', lastClassDayDate);

        let daysToCheck = [];
        let dayIterator = new Date(currentDay);

        while (dayIterator > lastClassDayDate) {
            daysToCheck.push(new Date(dayIterator));
            dayIterator.setDate(dayIterator.getDate() - 1);
        }

        if (logs) console.log('Days to Check:', daysToCheck);

        // const filteredRoster = roster.filter(student => 
        //   daysToCheck.some(date => {
        //     // Parse the student's birthday as UTC, treating it as midnight UTC
        //     const studentBirthday = new Date(Date.UTC(
        //       new Date(student.birthday).getUTCFullYear(),
        //       new Date(student.birthday).getUTCMonth(),
        //       new Date(student.birthday).getUTCDate()
        //     ));
        
        //     // Normalize the `date` to be treated as UTC
        //     const normalizedDate = new Date(Date.UTC(
        //       date.getUTCFullYear(),
        //       date.getUTCMonth(),
        //       date.getUTCDate()
        //     ));
        
        //     if (logs) console.log('StudentBirthday:', `${student.name} - ${student.birthday} normalized to ${studentBirthday}`);
        
        //     return (
        //       studentBirthday.getTime() === normalizedDate.getTime()
        //     );
        //   })
        // );

        const filteredRoster = roster.filter(student => 
          daysToCheck.some(date => {
            // Parse the student's birthday and normalize it to UTC
            const studentBirthday = new Date(Date.UTC(
              new Date(student.birthday).getUTCFullYear(),
              new Date(student.birthday).getUTCMonth(),
              new Date(student.birthday).getUTCDate()
            ));
            
            // Normalize the `date` to be UTC
            const normalizedDate = new Date(Date.UTC(
              date.getUTCFullYear(),
              date.getUTCMonth(),
              date.getUTCDate()
            ));
            
            // Compare only the month and day
            return (
              studentBirthday.getUTCMonth() === normalizedDate.getUTCMonth() &&
              studentBirthday.getUTCDate() === normalizedDate.getUTCDate()
            );
          })
        );

        if (logs) console.log('Filtered Roster (Birthdays):', filteredRoster);

        setBirthdays(filteredRoster);
      }
    }
  }, [user, classId]);

  // useEffect(() => {
  //   if (birthdays.length) {
  //     const lastDeploy = localStorage.getItem(`${classId}-lastBirthdayDeployDate`); // Switch to other version when testing.
  //     // const lastDeploy = new Date("2024-01-01").toISOString().split('T')[0]; // Switch this before deploying.
  //     const currentDayForCheck = new Date().toISOString().split('T')[0];
      
  //     if (logs) console.log('Last Deploy:', lastDeploy);
  //     if (logs) console.log('Current Day for Check:', currentDayForCheck);
      
  //     // Convert lastDeploy to a comparable format
  //     const lastDeployDateForCheck = lastDeploy ? new Date(lastDeploy).toISOString().split('T')[0] : null;
      
  //     if (logs) console.log('Formatted Last Deploy Date:', lastDeployDateForCheck);
      
  //     if (lastDeployDateForCheck !== currentDayForCheck) {
  //       const currentTime = new Date().getTime();
  //       const classIndex = user?.classes.findIndex((x) => x.classId === classId);
  //       const schedule = user?.classes[classIndex]?.schedule || {};
  //       const currentDay = new Date();
  //       const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //       const currentDayName = dayNames[currentDay.getDay()];
  //       const currentWeek = user.settings.multiWeek ? user.settings.currentWeek : "A";
  //       const currentClassStartTime = schedule[currentWeek][currentDayName].startTime
  //       if (logs) console.log('currentClassStartTime => ', currentClassStartTime);
  //       const classStartTime = parseTimeString(currentClassStartTime);

  //       if (logs) console.log('Current Time:', currentTime);
  //       if (logs) console.log('Class Start Time:', classStartTime);

  //       // Define min and max launch times
  //       const MIN_LAUNCH_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
  //       const MAX_LAUNCH_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds

  //       // Determine the launch time in milliseconds
  //       const launchTime = classData?.autoHappyBirthday?.launchTime === "random"
  //         ? MIN_LAUNCH_TIME + Math.random() * (MAX_LAUNCH_TIME - MIN_LAUNCH_TIME)
  //         : Number(classData?.autoHappyBirthday?.launchTime) * 60 * 1000 || 0;

  //       if (logs) console.log('Launch Time:', launchTime);

  //       const timeUntilDeploy = (classStartTime + launchTime) - currentTime;

  //       if (logs) console.log('Time Until Deploy:', timeUntilDeploy);

  //       if (timeUntilDeploy > 0) {
  //         const timerId = setTimeout(() => {
  //           if (logs) console.log('Launch Initiated');
  //           toggleBirthdayModal();
  //           localStorage.setItem(`${classId}-lastBirthdayDeployDate`, currentDay); // Uncomment this before deploying
  //         }, timeUntilDeploy);

  //         if (logs) console.log('Timer ID:', timerId);

  //         return () => clearTimeout(timerId);
  //       }
  //     }
  //   }
  // }, [birthdays, classId, classData]);


  // useEffect(() => {
  //   if (birthdays.length) {
  //     const lastDeploy = localStorage.getItem(`${classId}-lastBirthdayDeployDate`); 
  //     const currentDayForCheck = new Date().toISOString().split('T')[0];
      
  //     if (logs) console.log('Last Deploy:', lastDeploy);
  //     if (logs) console.log('Current Day for Check:', currentDayForCheck);
      
  //     const lastDeployDateForCheck = lastDeploy ? new Date(lastDeploy).toISOString().split('T')[0] : null;
      
  //     if (logs) console.log('Formatted Last Deploy Date:', lastDeployDateForCheck);
      
  //     if (lastDeployDateForCheck !== currentDayForCheck) {
  //       const currentTime = new Date().getTime();
  //       const classIndex = user?.classes.findIndex((x) => x.classId === classId);
  //       const schedule = user?.classes[classIndex]?.schedule || {};
  //       const currentDay = new Date();
  //       const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //       const currentDayName = dayNames[currentDay.getDay()];
  //       const currentWeek = user.settings.multiWeek ? user.settings.currentWeek : "A";
  //       const currentClassStartTime = schedule[currentWeek][currentDayName].startTime;
  //       if (logs) console.log('currentClassStartTime => ', currentClassStartTime);
  //       const classStartTime = parseTimeString(currentClassStartTime);

  //       if (logs) console.log('Current Time:', currentTime);
  //       if (logs) console.log('Class Start Time:', classStartTime);

  //       const MIN_LAUNCH_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
  //       const MAX_LAUNCH_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds

  //       const launchTime = classData?.autoHappyBirthday?.launchTime === "random"
  //         ? MIN_LAUNCH_TIME + Math.random() * (MAX_LAUNCH_TIME - MIN_LAUNCH_TIME)
  //         : Number(classData?.autoHappyBirthday?.launchTime) * 60 * 1000 || 0;

  //       if (logs) console.log('Launch Time:', launchTime);

  //       const timeUntilDeploy = (classStartTime + launchTime) - currentTime;

  //       if (logs) console.log('Time Until Deploy:', timeUntilDeploy);

  //       if (timeUntilDeploy > 0) {
  //         const timerId = setTimeout(() => {
  //           if (logs) console.log('Launch Initiated');
  //           toggleBirthdayModal();
  //           localStorage.setItem(`${classId}-lastBirthdayDeployDate`, currentDayForCheck); 

  //           if (classData?.autoHappyBirthday?.close !== "manual") {
  //             // Set a timeout to automatically close the modal after 30 seconds
  //             const autoCloseTimer = setTimeout(() => {
  //               if (logs) console.log('Auto-closing the modal');
  //               toggleBirthdayModal();
  //             }, Number(classData?.autoHappyBirthday?.close) * 1000);

  //             return () => clearTimeout(autoCloseTimer); // Cleanup autoCloseTimer
  //           }

  //         }, timeUntilDeploy);

  //         if (logs) console.log('Timer ID:', timerId);

  //         return () => clearTimeout(timerId);
  //       }
  //     }
  //   }
  // }, [birthdays, classId, classData]);

  useEffect(() => {
    if (birthdays.length) {
      const lastDeploy = localStorage.getItem(`${classId}-lastBirthdayDeployDate`); 
      const currentDayForCheck = new Date().toLocaleDateString('en-CA'); // Format as YYYY-MM-DD
      
      if (logs) console.log('Last Deploy:', lastDeploy);
      if (logs) console.log('Current Day for Check:', currentDayForCheck);
      
      const lastDeployDateForCheck = lastDeploy ? new Date(lastDeploy).toISOString().split('T')[0] : null;
      
      if (logs) console.log('Formatted Last Deploy Date:', lastDeployDateForCheck);
      
      if (lastDeployDateForCheck !== currentDayForCheck) {
        const currentTime = new Date().getTime();
        const classIndex = user?.classes.findIndex((x) => x.classId === classId);
        const schedule = user?.classes[classIndex]?.schedule || {};
        const currentDay = new Date();
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayName = dayNames[currentDay.getDay()];
        const currentWeek = user.settings.multiWeek ? user.settings.currentWeek : "A";
        const currentClassStartTime = schedule[currentWeek][currentDayName].startTime;
        if (logs) console.log('currentClassStartTime => ', currentClassStartTime);
        const classStartTime = parseTimeString(currentClassStartTime);
  
        if (logs) console.log('Current Time:', currentTime);
        if (logs) console.log('Class Start Time:', classStartTime);
  
        const MIN_LAUNCH_TIME = 5 * 60 * 1000; // 5 minutes in milliseconds
        const MAX_LAUNCH_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds
  
        const launchTime = classData?.autoHappyBirthday?.launchTime === "random"
          ? MIN_LAUNCH_TIME + Math.random() * (MAX_LAUNCH_TIME - MIN_LAUNCH_TIME)
          : Number(classData?.autoHappyBirthday?.launchTime) * 60 * 1000 || 0;
  
        if (logs) console.log('Launch Time:', launchTime);
  
        const timeUntilDeploy = (classStartTime + launchTime) - currentTime;
  
        if (logs) console.log('Time Until Deploy:', timeUntilDeploy);
  
        if (timeUntilDeploy > 0) {
          const timerId = setTimeout(() => {
            if (logs) console.log('Launch Initiated');
            toggleBirthdayModal();
            localStorage.setItem(`${classId}-lastBirthdayDeployDate`, currentDayForCheck); 
  
            if (classData?.autoHappyBirthday?.close !== "manual") {
              const autoCloseTime = Number(classData?.autoHappyBirthday?.close) * 1000 || 30000; // Default to 30 seconds if not specified
              const autoCloseTimer = setTimeout(() => {
                if (logs) console.log('Auto-closing the modal');
                setShowBirthdayModal(false); // Directly set the state to close the modal
              }, autoCloseTime);
  
              // Cleanup for autoCloseTimer
              return () => clearTimeout(autoCloseTimer);
            }
  
          }, timeUntilDeploy);
  
          // Cleanup for timerId
          return () => clearTimeout(timerId);
        }
      }
    }
  }, [birthdays, classId, classData]);

  const findLastActiveDay = (schedule, currentWeek, numberOfWeeks, currentDayName) => {
    const weekNames = ["A", "B", "C", "D", "E", "F", "G"];
    const dayNames = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
    
    let currentWeekIndex = weekNames.indexOf(currentWeek);
    if (logs) console.log('currentWeek:', currentWeek);
    if (logs) console.log('currentWeekIndex:', currentWeekIndex);

    let currentDayIndex = dayNames.indexOf(currentDayName);
    if (logs) console.log('currentDayName:', currentDayName);
    if (logs) console.log('currentDayIndex:', currentDayIndex);


    for (let weekOffset = 0; weekOffset < numberOfWeeks; weekOffset++) {
        let weekToCheckIndex = currentWeekIndex - weekOffset;
        if (logs) console.log('weekToCheckIndex:', weekToCheckIndex);
        if (weekToCheckIndex < 0) break;

        const weekToCheck = weekNames[weekToCheckIndex];
        if (logs) console.log('weekToCheck:', weekToCheck);

        for (let dayOffset = currentDayIndex === 0 ? 7 : currentDayIndex-1; dayOffset >= 0; dayOffset--) {
            const dayToCheck = dayNames[dayOffset];
            if (logs) console.log('dayToCheck:', dayToCheck);

            const daySchedule = schedule[weekToCheck]?.[dayToCheck];
            if (logs) console.log(`Checking ${weekToCheck} - ${dayToCheck}:`, daySchedule);

            if (daySchedule && daySchedule.active) {
                if (logs) console.log('Found Active Day:', dayToCheck);
                return dayToCheck;
            }
        }

        currentDayIndex = 6; // Reset to Sunday for the previous week
    }

    return null;
  };

  const toggleBirthdayModal = () => {
    if (logs) console.log('Inside toggleBirthdayModal()');
    setShowBirthdayModal(!showBirthdayModal);
  };

  if (logs && birthdays) console.log('showBirthdayModal:', showBirthdayModal);

  return (
    <>
      {location.pathname === "/auto-status" && (
        <Card>
          {/* <CardHeader className='h3 mb-0'>Auto Happy Birthday</CardHeader>
          <CardBody>
            <b>Next Reminder Message:</b> {nextReminderDisplay && nextReminderDisplay.reminderMessage || "No reminders scheduled for the rest of the day."}
            <br></br>
            <b>Next Reminder Start Time:</b> {nextReminderDisplay && formatCurrentAndStartTime(nextReminderDisplay.startTime) || "No reminders scheduled for the rest of the day."}
            <br></br>
            <b>Current System Time:</b> {currentSystemTimeDisplay}
            <br></br>
            <b>Time Until Launched:</b> {timeDifferenceDisplay && formatTimeUntilLaunched(timeDifferenceDisplay) || "No reminders scheduled for the rest of the day."}
          </CardBody>         */}
        </Card>
      )}
        <Modal isOpen={showBirthdayModal} toggle={toggleBirthdayModal}>
          {logs && console.log('Inside Modal')}
          <ModalBody>
            <div
              style={{
                backgroundImage: "url('/happy-birthday/1.png')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: 'relative',
                height: '600px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ef6549',
                textShadow: '2px 2px 4px rgba(0, 0, 0, 0.7)',
                fontSize: birthdays.length > 3 ? '20px' : '30px'
              }}
            >
              <h1 style={{ textAlign: 'center', marginTop: '40px' }}>
                {birthdays.map(birthday => birthday.name).join(', ')}!
              </h1>
            </div>
            <Button color="primary" onClick={() => toggleBirthdayModal()} style={{ marginTop: '20px' }}>
              Close
            </Button>
          </ModalBody>
        </Modal>
    </>
  );
};
export default UseAutoHappyBirthday;
