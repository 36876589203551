import { React, useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc } from "firebase/firestore";
import { db, storage } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation, useHistory } from 'react-router-dom';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

function AddEditCountdownForm() {
  const location = useLocation();
  const { state } = location;
  const item = state?.countdown;
  const user = useSelector(selectUser);
  const history = useHistory();
  const [validated, setValidated] = useState(false);
  const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek);
  const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2);
  const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes);
  const [currentWeek, setCurrentWeek] = useState("A");
  const [uploadAudioModal, setUploadAudioModal] = useState(false);
  const fileInputRef = useRef(null);
  const [uploadedAudioFile, setUploadedAudioFile] = useState();
  const [youtubeURL, setYoutubeURL] = useState('');
  const [youtubeLinkModal, setYoutubeLinkModal] = useState(false);
  const [audioFileErrorMessage, setAudioFileErrorMessage] = useState('');
  const [alert, setAlert] = useState(null);

  const fromForm = (location.pathname === '/countdown-form');

  console.log(item)

  const addWeeksToSchedule = async () => {
    if (item !== undefined) {
      if (!('G' in item.schedule)) {
        try {
          const docRef = doc(db, "users", user.uid);
    
          const userCopy = JSON.parse(JSON.stringify(user));
    
          const classIndex = userCopy.classes.findIndex(
            (c) => c.classId === data.classId
          );
    
          const newWeeks = {
            "E": {
              "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
            "F": {
              "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
            "G": {
              "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
          };
    
          userCopy.countdownTimers[state.index].schedule = { ...userCopy.countdownTimers[state.index].schedule, ...newWeeks };
    
          // Update the document in Firestore
          await setDoc(docRef, userCopy);

          // Update the data state
          setData((prevData) => ({
            ...prevData,
            schedule: { ...prevData.schedule, ...newWeeks },
          }));
    
        } catch (error) {
          console.error("Error updating schedule: ", error);
        }
      }
    }
  };

  useEffect(() => {
    addWeeksToSchedule();
  }, []);

  function cycleWeeks(direction) {
    const weekLetterOptions = ["A", "B", "C", "D", "E", "F", "G"];
    let currentIndex = weekLetterOptions.indexOf(currentWeek);

    if (direction === "forward") {
      if (currentIndex === numberOfWeeks-1) {
        currentIndex = 0;
      } else {
        currentIndex++;
      }
    } else if (direction === "backward") {
      if (currentIndex === 0) {
        currentIndex = numberOfWeeks-1;
      } else {
        currentIndex--;
      }
    }
  
    setCurrentWeek(weekLetterOptions[currentIndex]);
  }

  const blankSchedule = {
    "A": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "B": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "C": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "D": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "E": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "F": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "G": {
      "Monday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
  };

  const [data, setData] = useState({
    startTime: item?.startTime || "00:00",
    duration: item?.duration || 0,
    header: item?.header || '',
    active: item?.active === false ? false : item?.active === true ? true : true,
    alertMessage: item?.alertMessage || '',
    alertSound: item?.alertSound || 'none',
    alertSoundName: item?.alertSoundName || 'None',
    youtube: item?.youtube === false ? false : item?.youtube === true ? true : false,
    youtubeSrc: item?.youtubeSrc || '',
    days: [],
    schedule: item?.schedule || blankSchedule,
    modalClassName: item?.modalClassName || '',
    backdrop: item?.backdrop === false ? false : item?.backdrop === true ? true : true,
    customAudio: item?.customAudio === false ? false : item?.customAudio === true ? true : false,
    customAudioSrc: item?.customAudioSrc || '',
    customAudioName: item?.customAudioName || '',
    flash: item?.flash === false ? false : item?.flash === true ? true : false,
  });

  useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const docRef = doc(db, "users", user.uid);
      const userCopy = JSON.parse(JSON.stringify(user));

      const newCountdownTimer = {
        startTime: data.startTime,
        duration: data.duration,
        header: data.header,
        active: data.active,
        alertMessage: data.alertMessage,
        alertSound: data.alertSound,
        alertSoundName: data.alertSoundName,
        youtube: data.youtube,
        youtubeSrc: data.youtubeSrc,
        schedule: data.schedule,
        modalClassName: data.modalClassName,
        backdrop: data.backdrop,
        customAudio: data.customAudio,
        customAudioSrc: data.customAudioSrc,
        customAudioName: data.customAudioName
      };

      if (fromForm) {
        if (!userCopy.countdownTimers) {
          userCopy.countdownTimers = [];
        }

        if (item === undefined) {
          userCopy.countdownTimers.push(newCountdownTimer);
        } else {
          userCopy.countdownTimers[state.index] = data;
        }
      } else {
        if (!userCopy.classes[state.classId].contentBoxes[contentBoxId].countdownTimers) {
          userCopy.classes[state.classId].contentBoxes[contentBoxId].countdownTimers = [];
        }

        if (item === undefined) {
          userCopy.classes[state.classId].contentBoxes[contentBoxId].countdownTimers.push(newCountdownTimer);
        } else {
          userCopy.classes[state.classId].contentBoxes[contentBoxId].countdownTimers[state.index] = data;
        }
      }

      await setDoc(docRef, userCopy);
      successAlert();
    } catch (error) {
      window.alert('Error updating document:', error);
    } 
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Countdown Timer Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      ></ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
    history.push("/add-edit-classes");
  };

  const handleSingleStartTime = (e) => {
    const startTime = e.target.value;
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));

    for (const group in updatedSchedule) {
      for (const day in updatedSchedule[group]) {
        updatedSchedule[group][day] = { ...updatedSchedule[group][day], startTime };
      }
    }

    setData({ ...data, schedule: updatedSchedule, startTime: startTime });
  };

  const handleFormNonDays = (e) => {
    const { id, value, type, checked } = e.target;

    if (type === 'checkbox') {
      setData((prevData) => ({
        ...prevData,
        [id]: checked,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  };

  const handleAudioChange = (e) => {
    const { alertSound, alertSoundName, youtube, customAudio } = JSON.parse(e.target.value);

    if (youtube === true) {
      setData((prevData) => ({
        ...prevData,
        customAudio: false,
        youtube: true,
        alertSoundName: alertSoundName
      }));
    } else if (customAudio === true) {
      setData((prevData) => ({
        ...prevData,
        customAudio: true,
        youtube: false,
        alertSoundName: alertSoundName
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        alertSound: alertSound,
        alertSoundName: alertSoundName,
        youtube: false,
        customAudio: false
      }));
    }
  };

  const handleScheduleChange = (week, day, field, value) => {
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
    updatedSchedule[week][day][field] = value;
    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  };

  const toggleYoutubeLinkModal = () => {
    setYoutubeLinkModal(!youtubeLinkModal);
  };

  const handleYoutubeLinkSave = () => {
    setData((prevData) => ({
      ...prevData,
      customAudio: false,
      youtube: true,
      youtubeSrc: youtubeURL,
    }));
    toggleYoutubeLinkModal();
  };

  const toggleUploadAudioModal = () => {
    setUploadAudioModal(!uploadAudioModal);
  };

  const handleUploadAudioButtonClick = () => {
    fileInputRef.current.click();
  };

  const allowedTypes = ['audio/mpeg', 'audio/wav', 'audio/ogg'];
  const maxSize = 20 * 1024 * 1024; // 20MB

  const handleUploadAudioFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (!allowedTypes.includes(file.type)) {
        window.alert('Please select a valid audio file (MP3, WAV, OGG).');
        return;
      }

      if (file.size > maxSize) {
        window.alert('File size exceeds the 20MB limit.');
        return;
      }

      writeAudioFileToFirebase(file);
    }
  };

  const writeAudioFileToFirebase = async (audioFile) => {
    if (audioFile == null) return;
    const audioRef = ref(storage, `users/${user.uid}/${audioFile.name}`);
    uploadBytes(audioRef, audioFile).then((snapshot) => {
      setData((prevData) => ({
        ...prevData,
        customAudioName: snapshot.metadata.name,
        customAudio: true
      }));
      getDownloadURL(snapshot.ref).then((url) => {
        setData((prevData) => ({
          ...prevData,
          alertSound: url,
          customAudioSrc: url,
          youtube: false
        }));
        setUploadedAudioFile(url);
      });
    });
  };

  return (
    <>
      <Modal isOpen={youtubeLinkModal} modalClassName="youtubeLinkModal">
        <ModalHeader>Insert YouTube Link</ModalHeader>
        <ModalBody>
          <Row>
            <Input
              type="url"
              placeholder="Enter YouTube URL"
              value={youtubeURL}
              onChange={(e) => { setYoutubeURL(e.target.value);  }}
              style={{ marginTop: '10px' }}
            />
          </Row>
          <Row>
            <Button onClick={toggleYoutubeLinkModal}>Cancel</Button>
            <Button onClick={handleYoutubeLinkSave}>Save</Button>
          </Row>
        </ModalBody>
      </Modal>

      <div className="content">
        <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: fromForm ? '100vh' : null }}>
          <Col md={fromForm ? '8' : null}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add/Edit Countdown Timer</CardTitle>
              </CardHeader>
              <CardBody>
                <Form className="form-horizontal" noValidate validated={validated}>
                  <Row>
                    <Col>
                      <label style={{ fontSize: '14px' }}>Header Message</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: You should be in your seat by:"
                          type="text"
                          id="header"
                          value={data.header}
                          valid={data?.header.length <= 30}
                          invalid={data?.header.length > 30}
                        />
                        {data.header.length > 30 ? (
                          <FormFeedback invalid>Header Message is too long (max 30 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <label style={{ fontSize: '14px' }}>Alert Message</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: TIME'S UP!"
                          type="text"
                          id="alertMessage"
                          value={data.alertMessage}
                          valid={data.alertMessage.length <= 30}
                          invalid={data.alertMessage.length > 30}
                        />
                        {data.alertMessage.length > 30 ? (
                          <FormFeedback invalid>Alert Message is too long (max 30 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "30px", display: 'flex', justifyContent: 'space-evenly' }}>
                    {!differentTimes && (
                      <Col>
                        <UncontrolledTooltip delay={0} target={"startTime"} placement="top">
                          This is the time you want the countdown timer to appear on the screen and start counting down.
                        </UncontrolledTooltip>
                        <label id="startTime" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>Start Time</label>
                        <FormGroup>
                          <Input required onChange={(e) => handleSingleStartTime(e)} type="time" id="startTime" value={data.startTime} />
                        </FormGroup>
                      </Col>
                    )}
                    <UncontrolledTooltip delay={0} target={"duration"} placement="top">
                      This is how long in seconds you want your timer to last.
                    </UncontrolledTooltip>
                    <Col>
                      <label id="duration" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>Duration</label>
                      <FormGroup>
                        <Input required onChange={(e) => handleFormNonDays(e)} type="number" min={1} max={3600} id="duration" value={data.duration} />
                      </FormGroup>
                    </Col>
                    <UncontrolledTooltip delay={0} target={"alertSound"} placement="top">
                      Select the tone that you want to play at the end of the timer
                    </UncontrolledTooltip>
                    <Col>
                      <label id="locationTip" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>Location</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"locationTip"} placement="top">
                          This is the location on the present screen where the timer will appear.
                        </UncontrolledTooltip>
                        <Input onChange={(e) => handleFormNonDays(e)} type="select" id="modalClassName" value={data.modalClassName}>
                          <option value="center-top" label="Center-Top">Center-Top</option>
                          <option value="center-middle" label="Center-Middle">Center-Middle</option>
                          <option value="center-bottom" label="Center-Bottom">Center-Bottom</option>
                          <option value="right-top" label="Right-Top">Right-Top</option>
                          <option value="right-middle" label="Right-Middle">Right-Middle</option>
                          <option value="right-bottom" label="Right-Bottom">Right-Bottom</option>
                          <option value="left-top" label="Left-Top">Left-Top</option>
                          <option value="left-middle" label="Left-Middle">Left-Middle</option>
                          <option value="left-bottom" label="Left-Bottom">Left-Bottom</option>
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col>
                      <label id="alertSound" style={{ fontSize: '14px', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>Alert Sound</label>
                      <FormGroup>
                        <Input 
                          onChange={(e) => handleAudioChange(e)} 
                          type="select" 
                          id="alertSound"
                          // value={
                          //   data.youtube ? 
                          //     JSON.stringify({ youtubeSrc: data.youtubeSrc, youtube: true })
                          //   : 
                          //     JSON.stringify({ alertSound: data.alertSound, alertSoundName: data.alertSoundName, youtube: false })
                          // }
                          defaultValue={data?.alertSoundName}
                        >
                          <option value={JSON.stringify({ alertSound: "none", alertSoundName: "None" })} label="None">None</option>
                          <option value={JSON.stringify({ alertSound: "https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Falarm-sounds%2FAlarm-Short.mp3?alt=media&token=b988211b-30b7-4108-a7ad-426dcf2464f4", alertSoundName: "Short" })} label="Short">Short</option>
                          <option value={JSON.stringify({ alertSound: "https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Falarm-sounds%2FAlarm-Medium.mp3?alt=media&token=7a8ab57d-981b-44a1-924c-b002a1979a81", alertSoundName: "Medium" })} label="Medium">Medium</option>
                          <option value={JSON.stringify({ alertSound: "https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Falarm-sounds%2FAlarm-Long.mp3?alt=media&token=cce14722-fac4-4793-a7e5-abe75ee85799", alertSoundName: "Long" })} label="Long">Long</option>
                          {data?.customAudioSrc?.length > 0 && <option value={JSON.stringify({ alertSound: data.customAudioSrc, alertSoundName: data.customAudioName, customAudio: true })} label={data.customAudioName}>{data.customAudioName}</option>}
                          {data?.youtubeSrc?.length > 0 && <option value={JSON.stringify({ alertSound: data.youtubeSrc, alertSoundName: data.youtubeSrc, youtube: true })} label={data.youtubeSrc}>{data.youtubeSrc}</option>}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="uploadAlertSound" style={{ fontSize: '13px', display: 'flex', alignItems: 'left', flexDirection: 'column' }}>Upload Audio</label>
                      <Row>
                        <Col>
                          <i className="fa fa-upload" style={{ fontSize: '32px', cursor: 'pointer' }} onClick={() => handleUploadAudioButtonClick()}></i>
                          <input
                            type="file"
                            accept=".mp3,.wav,.ogg"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleUploadAudioFileChange}
                          />
                        </Col>
                        <Col>
                          <i className="fa fa-youtube" style={{ fontSize: '32px', cursor: 'pointer' }} onClick={toggleYoutubeLinkModal}></i>
                        </Col>
                      </Row>
                      {audioFileErrorMessage && <p style={{ color: 'red' }}>{audioFileErrorMessage}</p>}
                      <UncontrolledTooltip delay={0} target={"uploadAlertSound"} placement="top">
                        Upload custom audio to play at the end of your countdown.
                      </UncontrolledTooltip>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="flashTip" style={{ fontSize: '14px' }}>Flash</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"flashTip"} placement="top">
                          If checked, the screen will flash at the conclusion of the timer.
                        </UncontrolledTooltip>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          type="checkbox"
                          id="flash"
                          checked={data.flash}
                          defaultChecked={false}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '12px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="backgroundTip" style={{ fontSize: '14px' }}>Background</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"backgroundTip"} placement="top">
                          If checked, the background behind your timer will be darker.
                        </UncontrolledTooltip>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          type="checkbox"
                          id="backdrop"
                          checked={data.backdrop}
                          defaultChecked={true}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '12px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                      <label id="activeTip" style={{ fontSize: '14px' }}>Active</label>
                      <FormGroup>
                        <UncontrolledTooltip delay={0} target={"activeTip"} placement="top">
                          If checked, this timer will activate at the time requested.
                        </UncontrolledTooltip>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          type="checkbox"
                          id="active"
                          checked={data.active}
                          defaultChecked={true}
                          style={{
                            marginLeft: '-5px',
                            marginTop: '12px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {!multiWeek && !differentTimes &&
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"monday"}
                        placement="top"
                      >
                        Check if this class meets on Monday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.A.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"tuesday"}
                        placement="top"
                      >
                        Check if this class meets on Tuesday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.A.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"wednesday"}
                        placement="top"
                      >
                        Check if this class meets on Wednesday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.A.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"thursday"}
                        placement="top"
                      >
                        Check if this class meets on Thursday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.A.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"friday"}
                        placement="top"
                      >
                        Check if this class meets on Friday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.A.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }




                  {multiWeek && !differentTimes &&
                  <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    Week A
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="monday" 
                        checked={data.schedule?.A?.Monday?.active}
                        // value={"M"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule?.A?.Tuesday?.active}
                        // value={"T"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                   <Input 
                          onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule?.A?.Wednesday?.active}
                        // value={"W"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="thursday" 
                        checked={data.schedule?.A?.Thursday?.active}
                        // value={"Th"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="friday" 
                        checked={data.schedule?.A?.Friday?.active}
                        // value={"F"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>



                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    Week B
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("B", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="monday" 
                        checked={data.schedule?.B?.Monday?.active}
                        // value={"M"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule?.B?.Tuesday?.active}
                        // value={"T"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                   <Input 
                          onChange={(e) => handleScheduleChange("B", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule?.B?.Wednesday?.active}
                        // value={"W"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="thursday" 
                        checked={data.schedule?.B?.Thursday?.active}
                        // value={"Th"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="friday" 
                        checked={data.schedule?.B?.Friday?.active}
                        // value={"F"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                

                {numberOfWeeks === "3" && multiWeek && !differentTimes &&
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule?.C?.Monday?.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule?.C?.Tuesday?.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule?.C?.Wednesday?.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule?.C?.Thursday?.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data?.schedule?.C?.Friday?.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }

                  {numberOfWeeks === "4" && multiWeek && !differentTimes &&
                  <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule?.C?.Monday?.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule?.C?.Tuesday?.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule?.C?.Wednesday?.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule?.C?.Thursday?.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule?.C?.Friday?.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule?.D?.Monday?.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule?.D?.Tuesday?.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule?.D?.Wednesday?.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule?.D?.Thursday?.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data?.schedule?.D?.Friday?.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  </>
                  }
                  </>
                  }

{ differentTimes && !multiWeek &&
                  <Row>
                  <Col md="4">
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                    <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule["A"].Monday.active}
                          // value={"M"}
                          style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="8">
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleScheduleChange("A", "Monday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Monday.active ? data.schedule["A"].Monday.startTime : null}/>
                      </FormGroup>
                    </Col>
                    {/* <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleScheduleChange("A", "Monday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Monday.endTime}/>
                      </FormGroup>
                    </Col> */}
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                  <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule["A"].Tuesday.active}
                        // value={"T"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Tuesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Tuesday.active ? data.schedule["A"].Tuesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Tuesday", "endTime", e.target.checked)} type="time" id="endTime" value={data.schedule["A"].Tuesday.endTime}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                </Col>
                <Col md="4">
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                  <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule["A"].Wednesday.active}
                        // value={"W"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Wednesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Wednesday.active ? data.schedule["A"].Wednesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Wednesday", "endTime", e.target.checked)} type="time" id="endTime" value={data.schedule["A"].Wednesday.endTime}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                  <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)}
                        type="checkbox" 
                        id="thursday" 
                        checked={data.schedule["A"].Thursday.active}
                        // value={"Th"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Thursday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Thursday.active ? data.schedule["A"].Thursday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Thursday", "endTime", e.target.checked)} type="time" id="endTime" value={data.schedule["A"].Thursday.endTime}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                </Col>
                <Col md="4">
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'center'}}>
                  <Col md="4" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)}
                        type="checkbox" 
                        id="friday" 
                        checked={data.schedule["A"].Friday.active}
                        // value={"F"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="8">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Friday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Friday.active ? data.schedule["A"].Friday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Friday", "endTime", e.target.checked)} type="time" id="endTime" value={data.schedule["A"].Friday.endTime}/>
                    </FormGroup>
                  </Col> */}
                </Row>
                </Col>
                </Row>
                  }




                { differentTimes && multiWeek &&
                  <>
                  <Row style={{marginTop: "30px", marginBottom: '10px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <i className="nc-icon nc-minimal-left" style={{cursor: 'pointer'}} onClick={(() => cycleWeeks("backward"))}/>
                      <h3 style={{marginLeft: '15px', marginRight: '15px', marginBottom: '0px'}}>Week {currentWeek}</h3>
                    <i className="nc-icon nc-minimal-right"style={{cursor: 'pointer'}} onClick={(() => cycleWeeks("forward"))}/>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col md="3"></Col>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange(currentWeek, "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule[currentWeek]?.Monday.active}
                          // value={"M"}
                          style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px',marginTop: '12px'}}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                      <Input onChange={(e) => handleScheduleChange(currentWeek, "Monday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Monday.active ? data.schedule[currentWeek].Monday.startTime : null}/>
                      </FormGroup>
                    </Col>
                    {/* <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                      <Input onChange={(e) => handleScheduleChange(currentWeek, "Monday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Monday.active ? data.schedule[currentWeek].Monday.endTime : null}/>
                      </FormGroup>
                    </Col> */}
                    <Col md="3"></Col>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="3"></Col>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule[currentWeek]?.Tuesday.active}
                        // value={"T"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px',marginTop: '12px'}}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Tuesday.active ? data.schedule[currentWeek].Tuesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Tuesday.active ? data.schedule[currentWeek].Tuesday.endTime : null}/>
                    </FormGroup>
                  </Col> */}
                  <Col md="3"></Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="3"></Col>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule[currentWeek]?.Wednesday.active}
                        // value={"W"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px',marginTop: '12px'}}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Wednesday.active ? data.schedule[currentWeek].Wednesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Wednesday.active ? data.schedule[currentWeek].Wednesday.endTime : null}/>
                    </FormGroup>
                  </Col> */}
                  <Col md="3"></Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="3"></Col>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="thursday" 
                        checked={data.schedule[currentWeek]?.Thursday.active}
                        // value={"Th"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px',marginTop: '12px'}}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Thursday.active ? data.schedule[currentWeek].Thursday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Thursday.active ? data.schedule[currentWeek].Thursday.endTime : null}/>
                    </FormGroup>
                  </Col> */}
                  <Col md="3"></Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="3"></Col>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Friday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="friday" 
                        checked={data.schedule[currentWeek]?.Friday.active}
                        // value={"F"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px',marginTop: '12px'}}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Friday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Friday.active ? data.schedule[currentWeek].Friday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  {/* <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Friday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Friday.active ? data.schedule[currentWeek].Friday.endTime : null}/>
                    </FormGroup>
                  </Col> */}
                  <Col md="3"></Col>
                </Row>
                </>
                  }
                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-round" color="info" type="submit"  onClick={(e) => handleSubmit(e)}>
                  Save
                </Button>
              </CardFooter>
            </Card>
            {alert}
          </Col>
        </Row>
      </div>


      <Modal
            isOpen={uploadAudioModal}
            toggle={toggleUploadAudioModal}
            backdrop={true}
            scrollable
            style={{ padding: '20px' }}
          >
            <ModalHeader toggle={toggleUploadAudioModal}></ModalHeader>
            <ModalBody>
              <Input type="file"></Input>
            </ModalBody>
            <Button onClick={null}>Save Clock Version</Button>
          </Modal>
    </>
  );
}

export default AddEditCountdownForm;
